import { TOurReviewForm } from './OurReviewForm';
import { ServiceReview } from '../../models';

export const getFrontendReview = (): TOurReviewForm => ({
  text: '',
  score: 5,
});

export const getReviewForFrontend = (review: ServiceReview | null): TOurReviewForm => {
  return { text: review?.text || '', score: review?.score || 5 };
};

export const getReviewForBackend = (review?: TOurReviewForm) => {
  if (!review) return null;

  const markDown = review.text || '';

  const hasReview = markDown !== '';

  const reviewForBackend = hasReview ? { text: markDown, score: review.score } : null;

  // TODO: Возможно так и останется, но пока бек не принимает null
  // hasReview ? { text: markDown, score: review.score } : { text: '', score: review.score }

  return reviewForBackend;
};
