import { Form, Layout, notification, Spin } from 'antd';
import { FormProviderProps } from 'antd/lib/form/context';
import { Content } from 'antd/lib/layout/layout';
import React, { FC } from 'react';
import { LabelKeyValueType } from 'utils/types';
import { EntityHeader } from '../../components/EntityHeader';
import { FormHeader } from '../../components/FormHeader';
import { Logo } from '../../components/Logo';
import { TabsWithButtons } from '../../components/TabsWithButtons/TabsWithButtons';
import { socialRoleKeys, socialRoleTabs, useTabs } from '../../hooks/useTabs';
import { ImageEntity } from '../../hooks/useUploadImage';
import { showFormErrorMessages } from '../../utils/showFormErrorMessages';
import { BaseSocialRoleForm, SocialRoleBaseData } from './forms/BaseSocialRoleForm/BaseSocialRoleForm';
import { NeedsForm } from './forms/NeedsForm';
import { SocialRoleForm } from './types';

export type LinkedNeeds = Array<LabelKeyValueType>;

const getTabs = (id: string | undefined) => {
  const isNewRole = id === 'new';
  return isNewRole ? socialRoleTabs.filter(({ key }) => ![socialRoleKeys.icon].includes(key)) : socialRoleTabs;
};
type SocialRoleContentProps = {
  id: string | undefined;
  onSubmit: (values: SocialRoleForm) => void;
  isLoading: boolean;
  onDelete: () => void;
  initialValues: {
    baseData: SocialRoleBaseData;
    iconForm?: {
      src: string;
      id: string;
    };
    needs: LinkedNeeds;
  };
};

export const SocialRoleContent: FC<SocialRoleContentProps> = ({ initialValues, id, onSubmit, onDelete, isLoading }) => {
  const { activeTab, tabs, setActiveTab, goToNextTab, goToPrevTab } = useTabs(getTabs(id));

  const tabContent = {
    [socialRoleKeys.common]: <BaseSocialRoleForm {...initialValues.baseData} roleId={id} />,
    [socialRoleKeys.icon]: (
      <Logo
        entity={ImageEntity.SocialRole}
        className={'d-flex flex-justify-center'}
        logo={initialValues.iconForm?.src}
        logoId={initialValues.iconForm?.id}
        withImageRemove={false}
      />
    ),
    [socialRoleKeys.linkedNeeds]: (
      <NeedsForm
        isActiveForm={activeTab === socialRoleKeys.linkedNeeds}
        initialValues={{
          needs: initialValues.needs,
        }}
      />
    ),
  };
  const onFormFinish: FormProviderProps['onFormFinish'] = async (_, { forms }) => {
    const errorMessage = () => notification.error({ message: 'Необходимо заполнить все поля корректно' });
    try {
      const validation = await Promise.allSettled(Object.values(forms).map((f) => f.validateFields()));

      const hasInvalidValues = validation.find((v) => v.status === 'rejected');

      if (!hasInvalidValues) {
        const data = {};
        Object.entries(forms).forEach(([_, form]) => {
          Object.assign(data, form.getFieldsValue());
        });
        onSubmit({
          baseData: forms.base.getFieldsValue(),
          needs: forms.needsForm.getFieldsValue(),
        });
      } else {
        showFormErrorMessages(validation);
      }
    } catch (error) {
      errorMessage();
    }
  };
  if (isLoading) {
    return (
      <Layout>
        <Spin spinning={isLoading} />
      </Layout>
    );
  }
  return (
    <Layout>
      <Form.Provider onFormFinish={onFormFinish}>
        <div>
          <EntityHeader
            title={id !== 'new' ? initialValues.baseData.title : 'Новая роль'}
            onArchiveButtonClick={isNaN(Number(id)) ? undefined : onDelete}
          />
          <TabsWithButtons
            filteredTabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onLeftButtonClick={goToPrevTab}
            onRightButtonClick={goToNextTab}
          />
          <Content className="mt-40 p-16 rounded-16 bg-neutral">
            {tabs.map(({ key, label, formHeaderType, contentClassName }) => {
              return (
                <div className={contentClassName} key={key}>
                  <FormHeader title={label} type={formHeaderType} />
                  <div>{tabContent[key]}</div>
                </div>
              );
            })}
          </Content>
        </div>
      </Form.Provider>
    </Layout>
  );
};
