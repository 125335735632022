import React, { useMemo, useState } from 'react';
import { axios } from 'client';
import { Index } from 'client/endpoints';
import { PageTitle } from 'components/PageTitle';
import { Content } from 'antd/lib/layout/layout';
import { Button, Layout, List, Modal, ModalProps, notification, Pagination, Tabs } from 'antd';
import { StyledInput } from '../../components/StyledInputs/StyledInput';
import { StyledSelectComponent } from '../../components/StyledSelect';
import { TagDto } from '../../models';
import { useQuery } from 'react-query';

type TTagCategory = 'service' | 'place' | 'event';

type NewTagModalProps = Omit<ModalProps, 'onOk'> & {
  onOk: (category: TTagCategory, tagName: string) => void;
};

const tagCategoryOptions: { value: TTagCategory; label: string }[] = [
  { value: 'service', label: 'Для сервиса' },
  { value: 'place', label: 'Для места' },
  { value: 'event', label: 'Для события' },
];

const NewTagModal: React.FC<NewTagModalProps> = ({ onOk, ...modalProps }) => {
  const [tagFor, setTagFor] = useState<Optional<TTagCategory>>();
  const [tagName, setTagName] = useState<string>('');

  const handleOkButtonClick = () => {
    if (tagFor) {
      onOk(tagFor, tagName);
    }
  };

  const isOnButtonDisabled = typeof tagFor === 'undefined' || tagName.trim().length === 0;

  return (
    <Modal
      {...modalProps}
      title="Добавить тег"
      onOk={handleOkButtonClick}
      okButtonProps={{ disabled: isOnButtonDisabled }}
    >
      <StyledSelectComponent
        value={tagFor}
        className="w-100"
        onChange={setTagFor}
        placeholder="Категория тега"
        options={tagCategoryOptions}
      />
      <StyledInput
        className="mt-24"
        value={tagName}
        placeholder="Название тега"
        onChange={(e) => setTagName(e.target.value)}
      />
    </Modal>
  );
};

export const Tags = () => {
  const [activeTab, setActiveTab] = useState<TTagCategory>('service');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: 8,
  });

  const handleChangeActiveTab = (tab: TTagCategory) => {
    setPaginationData({ current: 1, pageSize: 8 });
    setActiveTab(tab);
  };

  const fetchServiceTags = async () => {
    const { data } = await axios.get(
      Index.services +
        `/tags?&offset=${
          paginationData.current === 1 ? '0' : (paginationData.current - 1) * paginationData.pageSize
        }&limit=${paginationData.pageSize}`,
    );
    return data;
  };

  const fetchPlaceTags = async () => {
    const { data } = await axios.get(
      Index.places +
        `/tags?&offset=${
          paginationData.current === 1 ? '0' : (paginationData.current - 1) * paginationData.pageSize
        }&limit=${paginationData.pageSize}`,
    );
    return data;
  };

  const fetchEventTags = async () => {
    const { data } = await axios.get(
      Index.adminEvents +
        `/tags?&offset=${
          paginationData.current === 1 ? '0' : (paginationData.current - 1) * paginationData.pageSize
        }&limit=${paginationData.pageSize}`,
    );
    return data;
  };

  const { data: serviceTagsData, refetch: refetchServiceTags } = useQuery<BaseResponse<TagDto[], PageMeta>>(
    ['serviceTags', paginationData],
    fetchServiceTags,
    { enabled: activeTab === 'service' },
  );

  const { data: placeTagsData, refetch: refetchPlaceTags } = useQuery<BaseResponse<TagDto[], PageMeta>>(
    ['placeTags', paginationData],
    fetchPlaceTags,
    { enabled: activeTab === 'place' },
  );

  const { data: eventTagsData, refetch: refetchEventTags } = useQuery<BaseResponse<TagDto[], PageMeta>>(
    ['eventTags', paginationData],
    fetchEventTags,
    { enabled: activeTab === 'event' },
  );

  const serviceTags = serviceTagsData?.data;
  const eventTags = eventTagsData?.data;
  const placeTags = placeTagsData?.data;

  const tagTabs: {
    tab: string;
    key: TTagCategory;
    total?: number;
  }[] = useMemo(
    () => [
      {
        tab: 'Теги для сервисов',
        key: 'service',
        total: serviceTagsData?.meta?.total,
      },
      {
        tab: 'Теги для мест',
        key: 'place',
        total: placeTagsData?.meta?.total,
      },
      {
        tab: 'Теги для событий',
        key: 'event',
        total: eventTagsData?.meta?.total,
      },
    ],
    [serviceTagsData?.meta?.total, placeTagsData?.meta?.total, eventTagsData?.meta?.total],
  );

  const getRefetchAndBaseUrl = (category: TTagCategory) => {
    switch (category) {
      case 'event':
        return { baseUrl: Index.adminEvents, refetch: refetchEventTags };
      case 'place':
        return { baseUrl: Index.places, refetch: refetchPlaceTags };
      case 'service':
        return { baseUrl: Index.services, refetch: refetchServiceTags };
      default:
        return {};
    }
  };

  const handleDeleteTag = async (category: TTagCategory, id: number) => {
    const { baseUrl, refetch } = getRefetchAndBaseUrl(category);

    try {
      await axios.delete(baseUrl + `/tags/${id}`);
      notification.success({ message: `Teг удален` });
      refetch?.();
    } catch (error) {
      notification.error({ message: 'Ошибка при удалени тега' });
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleSaveNewTag = async (category: TTagCategory, name: string) => {
    const { baseUrl, refetch } = getRefetchAndBaseUrl(category);

    try {
      await axios.post(baseUrl + '/tags', { name });
      notification.success({ message: `Teг '${name}' сохранен` });
      refetch?.();
    } catch (error) {
      notification.error({ message: 'Ошибка при создании тега' });
    } finally {
      setIsModalOpen(false);
    }
  };

  const tagsData = useMemo(() => {
    if (activeTab === 'event') {
      return eventTags;
    } else if (activeTab === 'place') {
      return placeTags;
    } else if (activeTab === 'service') {
      return serviceTags;
    } else {
      return [];
    }
  }, [serviceTags, placeTags, eventTags, activeTab]);

  return (
    <div>
      <PageTitle
        title="Теги"
        titleClassName="mb-24"
        wrapperClassName="mb-24"
        onAddButtonClick={() => setIsModalOpen(true)}
      />
      <Layout>
        <Content className="mt-40 p-16 rounded-16 bg-neutral">
          <Tabs onChange={(activeKey) => handleChangeActiveTab(activeKey as TTagCategory)} activeKey={activeTab}>
            {tagTabs.map((t) => (
              <Tabs.TabPane {...t} key={t.key}>
                <List
                  itemLayout="horizontal"
                  dataSource={tagsData}
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Button onClick={() => handleDeleteTag(t.key, item.id)} type="link" danger key="delete">
                          Удалить
                        </Button>,
                      ]}
                    >
                      {item.name}
                    </List.Item>
                  )}
                />
                <Pagination
                  {...paginationData}
                  total={t.total}
                  onChange={(page, pageSize) => {
                    setPaginationData({ current: page, pageSize });
                  }}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Content>
      </Layout>
      <NewTagModal
        destroyOnClose
        open={isModalOpen}
        okText="Сохранить"
        onOk={handleSaveNewTag}
        onCancel={() => setIsModalOpen(false)}
      />
    </div>
  );
};
