import { notification, Spin } from 'antd';
import useAxios from 'axios-hooks';
import { setAxiosRequestHeaders } from 'client/axios';
import { UserAuthResponseDto } from 'models';
import moment from 'moment';
import { NAVIGATION } from 'paths';
import React, { createContext, PropsWithChildren, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeAllExceptNumbers } from '../../utils';

type UserContextValue = {
  user?: UserAuthResponseDto;
  logout: () => void;
  authorize: (login: string, phone: string) => void;
};

export const UserContext = createContext<UserContextValue>({
  logout: () => null,
  authorize: () => null,
});

const setUserConfig = (user: UserAuthResponseDto) => {
  setAxiosRequestHeaders({ 'x-account-token': user.accessTokens[0].accessToken });
};

const getUserFromLocaleStorage = () => {
  try {
    const userFromLocaleStorage = localStorage.getItem('user');
    if (userFromLocaleStorage) {
      const user = JSON.parse(userFromLocaleStorage);
      const accessTokenExpire = user?.accessTokens?.[0]?.accessTokenExpire;

      const date = moment(accessTokenExpire);
      const duration = moment(date).diff(moment(new Date()), 'hours');
      if (duration > 1) {
        setUserConfig(user);
        return user;
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const UserContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<Optional<UserAuthResponseDto>>(getUserFromLocaleStorage());
  const [{ loading }, loginUser] = useAxios<BaseResponse<UserAuthResponseDto>>(
    { url: '/v1/login', method: 'POST' },
    { manual: true },
  );

  const authorize = useCallback(
    async (login: string, password: string) => {
      try {
        const formattedPhoneNumber = removeAllExceptNumbers(login);
        const userResponse = await loginUser({ data: { login: formattedPhoneNumber, password } });
        const user = userResponse.data.data;
        localStorage.setItem('user', JSON.stringify(user));
        navigate(NAVIGATION.news);
        setUserConfig(user);
        setCurrentUser(user);
      } catch (error) {
        notification.error({ message: 'Неверный логин или пароль' });
      }
    },
    [loginUser, navigate],
  );

  const logout = useCallback(() => {
    localStorage.clear();
    setCurrentUser(undefined);
  }, []);

  return (
    <Spin size="large" spinning={loading}>
      <UserContext.Provider value={{ user: currentUser, authorize, logout }}>{children}</UserContext.Provider>
    </Spin>
  );
};
