import React, { FC } from 'react';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FieldData } from 'rc-field-form/lib/interface';
import moment from 'moment';
import { AROUND_THE_CLOCK, NO_SCHEDULE } from 'utils';
import { ScheduleFormProps } from './ScheduleFormTypes';
import { ScheduleFormSettings } from './ScheduleFormSettings';
import { ScheduleFormItem } from './ScheduleFormItem';

const days = [
  { name: 'monday', ruName: 'Понедельник' },
  { name: 'tuesday', ruName: 'Вторник' },
  { name: 'wednesday', ruName: 'Среда' },
  { name: 'thursday', ruName: 'Четверг' },
  { name: 'friday', ruName: 'Пятница' },
  { name: 'saturday', ruName: 'Суббота' },
  { name: 'sunday', ruName: 'Воскресенье' },
];

const getFullDay = () => {
  const date = moment();
  date.set('hour', 0).set('minute', 0);

  return moment(date);
};

export const ScheduleForm: FC<ScheduleFormProps> = ({ initialValues }) => {
  const [form] = useForm<ScheduleFormProps['initialValues']>();
  const toggleDaysSchedule = (enabled: boolean, isFulldaySchedule?: boolean) => {
    const newFields: FieldData[] = days.reduce<FieldData[]>((acc, { name }) => {
      const workTime = isFulldaySchedule && enabled ? [getFullDay(), getFullDay()] : undefined;

      acc.push(
        { name: ['days', name, 'enabled'], value: enabled },
        { name: ['days', name, 'hours'], value: workTime },
        { name: ['days', name, 'break'], value: undefined },
      );
      return acc;
    }, []);

    form.setFields(newFields);
  };

  const handleAllTimeButtonChange = (checked: boolean) => {
    toggleDaysSchedule(checked, true);
    if (checked) {
      form.setFieldValue(NO_SCHEDULE, false);
    }
  };

  const handleResetButtonClick = (day: string) => {
    form.setFields([
      { name: ['days', day, 'hours'], value: undefined },
      { name: ['days', day, 'break'], value: undefined },
    ]);
  };

  const handleDayClick = (checked: boolean, day: string) => {
    if (!checked) {
      handleResetButtonClick(day);
    }
  };

  return (
    <Form layout="vertical" form={form} name="schedule" initialValues={initialValues}>
      <div className="mb-24 d-flex flex-justify-end">
        <ScheduleFormSettings aroundTheClockButtonClick={handleAllTimeButtonChange} />
      </div>
      {days.map(({ name, ruName }) => {
        return (
          <ScheduleFormItem
            key={name}
            name={name}
            title={ruName}
            onDayEnableClick={handleDayClick}
            onResetButtonClick={handleResetButtonClick}
          />
        );
      })}
    </Form>
  );
};
