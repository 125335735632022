import useAxios from 'axios-hooks';
import { useParams } from 'react-router-dom';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import { ImageEntity, ImageType } from 'hooks/useUploadImage';

export const useServiceDeleteImage = (
  entity: ImageEntity = ImageEntity.Services,
  imageType: ImageType,
  onSuccess?: (data?: string) => void,
) => {
  const { id: serviceId } = useParams<{ id: string }>();
  const deleteImage = useAxios({ method: 'DELETE' }, { manual: true })[1];

  return async (imageId: string) => {
    const url = `/admin/v1/${entity}/${serviceId}/images/${imageType}/${imageId}`;

    try {
      await deleteImage({ url });
      return onSuccess?.(imageId);
    } catch (error) {
      const e = error as AxiosError;
      if (e.request.status === 404) {
        notification.error({
          message: 'Изображение уже было удалено',
        });
        return;
      }
      notification.error({
        message: 'При удалении изображения что-то пошло не так. Попробуйте позднее',
      });
    }
  };
};
