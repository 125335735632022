import React, { useState } from 'react';
import { Avatar, Button, Spin, Typography, Upload } from 'antd';
import { FormHeader } from 'components/FormHeader';
import { UploadOutlined } from '@ant-design/icons';
import { useEntityUploadImage } from 'hooks';
import { ImageEntity, ImageType } from 'hooks/useUploadImage';
import { useServiceDeleteImage } from 'client/hooks/services';
import styles from './styles.module.css';

type LogoProps = {
  logo?: string;
  logoId?: string;
  title?: string;
  entity: ImageEntity;
  className?: string;
  withImageRemove?: boolean;
};

export const Logo: React.FC<LogoProps> = ({ logo, title, entity, className, logoId, withImageRemove = true }) => {
  const [logoImage, setLogoImage] = useState<{ src?: string; id?: string }>({
    src: logo,
    id: logoId,
  });
  const deleteImage = useServiceDeleteImage(entity, ImageType.Icon, () => {
    setLogoImage((prev) => {
      return { ...prev, src: '', id: undefined };
    });
  });
  const { uploadImage: customRequest, isLoading } = useEntityUploadImage(ImageType.Icon, entity, async (data) => {
    if (data[0]) {
      if (logoImage.id !== undefined && withImageRemove) {
        await deleteImage(logoImage.id);
      }
      setTimeout(() => {
        setLogoImage({ src: data[0].url, id: data[0].uid });
      }, 100);
    }
  });

  return (
    <div className={className}>
      {title !== undefined && <FormHeader title={title} type="fullwidth" level={4} />}
      <div className="d-flex gap-8 flex-items-center">
        <div className={styles.loadingWrapper}>
          <div className={`${styles.spinContainer} ${!isLoading ? styles.hiddenSpinner : ''}`}>
            <Spin />
          </div>
          <div className={`${styles.loadingComponent} ${!isLoading ? styles.hiddenContainer : ''}`}>
            <Avatar
              size={88}
              shape="square"
              src={logoImage.src}
              style={{ border: '1px solid #434343', backgroundColor: 'transparent' }}
            />
          </div>
        </div>
        <div className="d-flex flex-column gap-8">
          <Typography.Text className="text-gray-7">
            Иконка должна быть квадратного формата, в формате png или jpeg.
          </Typography.Text>
          <div className={'d-flex gap-16'}>
            <Upload accept=".jpeg,.jpg,.png" customRequest={customRequest} showUploadList={false}>
              <Button type="primary" icon={<UploadOutlined />}>
                Загрузить новую
              </Button>
            </Upload>
            {logoImage && logoImage.id !== undefined && withImageRemove && (
              <Button
                danger
                onClick={() => {
                  if (logoImage.id) {
                    return deleteImage(logoImage.id);
                  }
                }}
              >
                Удалить изображение
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
