import { TSocialRole } from 'forms/SocialForm/SocialForm';

const allUrls = ['Facebook', 'Instagram', 'Telegram', 'YouTube', 'Twitter', 'Twitch', 'OK', 'VK', 'WEB'];

export const socialUrls: TSocialRole[] = allUrls.map((name) => ({ name, url: undefined }));

export const getMergedSocials = (socials?: Nullable<TSocialRole[]>): TSocialRole[] => {
  return (socials || []).reduce<TSocialRole[]>((acc, item) => {
    if (typeof item?.name === 'string' && typeof item?.url === 'string') {
      const isBaseSocial = !!acc.find((i) => i.name === item.name);

      if (isBaseSocial) {
        return acc.map((i) => (i.name === item.name ? { ...i, url: item.url } : i));
      } else {
        return [...acc, { name: item.name, url: item.url }];
      }
    }
    return acc;
  }, socialUrls);
};
