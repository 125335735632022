import { Form, Layout, notification } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FormProviderProps } from 'antd/lib/form/context';
import { Content } from 'antd/lib/layout/layout';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getTextEditorValidationError } from 'utils/getTextEditorValidationError';
import { EntityHeader } from '../../components/EntityHeader';
import { FormHeader } from '../../components/FormHeader';
import { TabsWithButtons } from '../../components/TabsWithButtons/TabsWithButtons';
import { AUTHOR_DESCRIPTION_MAX_LENGTH } from '../../constants';
import { authorsTabKeys, authorsTabs, TAB_PREFIX, useTabs } from '../../hooks/useTabs';
import { showFormErrorMessages } from '../../utils/showFormErrorMessages';
import { AuthorImage } from './AuthorImage';
import { AuthorBaseInfoForm } from './forms/AuthorBaseInfoForm';
import { AuthorBindingsForm } from './forms/AuthorBindingsForm';
import { AuthorRolesForm } from './forms/AuthorRolesForm';
import { FrontendAuthorItem } from './types';
import { useSocialRolesList } from 'client/hooks/socialRoles';
import { rolesMapper } from 'client/mappers/author';

type Props = {
  authorData: FrontendAuthorItem;
  onDeleteButtonClick: () => void;
  onSave: (authorDataForSave: FrontendAuthorItem, file?: string | Blob | RcFile) => void;
  isCreationMode?: boolean;
};

export const AuthorContent: React.FC<Props> = ({ authorData, onSave, onDeleteButtonClick, isCreationMode }) => {
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get(TAB_PREFIX)?.toString();
  const { activeTab, tabs, setActiveTab, goToNextTab, goToPrevTab } = useTabs(authorsTabs, initialTab);
  const [imageFile, setImageFile] = useState<string | Blob | RcFile | null>(null);

  const authorInitialValues = { ...authorData };

  const { roles } = useSocialRolesList(activeTab === authorsTabKeys.roles);

  const mappedRoles = rolesMapper(roles);

  const tabContent = {
    [authorsTabKeys.common]: <AuthorBaseInfoForm initialValues={authorInitialValues} />,
    [authorsTabKeys.gallery]: (
      <AuthorImage
        image={authorData.image}
        authorId={authorData.id}
        isCreationMode={isCreationMode}
        setImageFile={setImageFile}
      />
    ),
    [authorsTabKeys.roles]: <AuthorRolesForm options={mappedRoles} initialValues={{ roles: authorData.roles }} />,
    [authorsTabKeys.bindings]: (
      <AuthorBindingsForm
        isActiveForm={activeTab === authorsTabKeys.bindings}
        id={authorData.id}
        initialValues={authorInitialValues}
      />
    ),
  };

  const onFormFinish: FormProviderProps['onFormFinish'] = async (_, { forms }) => {
    const errorMessage = () => notification.error({ message: 'Необходимо заполнить все поля корректно' });
    try {
      const validation = await Promise.allSettled(Object.values(forms).map((f) => f.validateFields()));

      const descriptionTextLengthError = getTextEditorValidationError(forms, AUTHOR_DESCRIPTION_MAX_LENGTH);

      const hasInvalidValues = validation.find((v) => v.status === 'rejected') || descriptionTextLengthError;

      const { roles } = forms;

      const socialRoles = roles.getFieldValue('roles');

      if (socialRoles && socialRoles?.length === 0)
        return notification.error({
          message: 'Не все поля заполнены корректно:',
          description: (
            <>
              • Связанные роли
              <br />
            </>
          ),
        });

      if (!hasInvalidValues) {
        const data = {};
        Object.entries(forms).forEach(([_, form]) => {
          Object.assign(data, form.getFieldsValue());
        });
        onSave(data as FrontendAuthorItem, imageFile as string | Blob | RcFile);
      } else {
        showFormErrorMessages(
          validation,
          descriptionTextLengthError
            ? `Описание должно быть менее ${AUTHOR_DESCRIPTION_MAX_LENGTH} символов`
            : undefined,
        );
      }
    } catch (error) {
      errorMessage();
    }
  };

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Layout>
        <EntityHeader
          title={authorData.name || 'Создать Автора'}
          onArchiveButtonClick={!isCreationMode ? onDeleteButtonClick : undefined}
        />
        <TabsWithButtons
          filteredTabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onLeftButtonClick={goToPrevTab}
          onRightButtonClick={goToNextTab}
        />
        <Content className="mt-40 p-16 rounded-16 bg-neutral">
          {tabs.map(({ key, label, contentClassName, formHeaderType }) => {
            return (
              <div key={key} className={contentClassName}>
                <FormHeader title={label} type={formHeaderType} />
                <div>{tabContent[key]}</div>
              </div>
            );
          })}
        </Content>
      </Layout>
    </Form.Provider>
  );
};
