type ErrorObj = { errors: string[] };

function getErrors(obj: ErrorObj[]): string[] {
  return obj.flatMap((errorObj) => errorObj.errors ?? []);
}

export type ValidationResult = PromiseSettledResult<{
  errorFields?: ErrorObj[];
}>;

export const getFormErrorMessages = (validation: ValidationResult[]): string[] => {
  const errors = validation.reduce((acc, item) => {
    return item.status === 'rejected' ? [...acc, ...item.reason.errorFields] : acc;
  }, [] as ErrorObj[]);

  return getErrors(errors);
};
