import { notification } from 'antd';
import useAxios from 'axios-hooks';
import { useSocialNeeds } from 'client/hooks/needs';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LabelKeyValueType } from 'utils/types';
import { Index } from '../../client/endpoints';
import { ClientErrorException } from '../../components/ClientErrorException';
import { AdminNeedByDataResponseDto } from '../../models';
import { NAVIGATION } from '../../paths';
import { NeedContent } from './NeedContent';

export type NeedFormValues = {
  title: string;
  roles: Array<LabelKeyValueType>;
};

export const Need = () => {
  const { id } = useParams<{ id: string }>();
  const isCreationMode = id === 'new';
  const navigate = useNavigate();
  const { changeNeed, addNeed, removeNeed } = useSocialNeeds();
  const saveNewNeed = useAxios<AdminNeedByDataResponseDto>({ url: Index.needs, method: 'POST' }, { manual: true })[1];
  const removeNeedRequest = useAxios<BaseResponse<void>>(
    { url: `${Index.needs}/${id}`, method: 'DELETE' },
    { manual: true },
  )[1];

  const changeExistsNeed = useAxios<{ name: string; needs: [] }>(
    { url: `${Index.needs}/${id}`, method: 'PATCH' },
    { manual: true },
  )[1];

  const [{ data: need, loading: isNeedLoading, error }] = useAxios<BaseResponse<AdminNeedByDataResponseDto['data']>>(
    { url: `${Index.needs}/${id}`, method: 'GET' },
    { manual: isCreationMode },
  );
  const archivePlace = async () => {
    try {
      if (id !== undefined) {
        await removeNeedRequest({ data: { id: id } });
        removeNeed(+id);
        notification.success({
          message: `Потребность успешно удалена`,
        });
        navigate(NAVIGATION.needs);
      }
    } catch (e) {
      notification.error({ message: 'Произошла ошибка' });
    }
  };

  const onFormFinish = async (data: NeedFormValues) => {
    const mappedRolesToServerRequest = data.roles.map((item) => {
      return item.key;
    });

    try {
      if (isCreationMode) {
        const createdNeed = await saveNewNeed({
          data: { name: data.title, roles: mappedRolesToServerRequest },
        });
        const {
          data: { data: createdNeedData },
        } = createdNeed;

        const roles =
          createdNeedData.roles && createdNeedData.roles.length >= 1
            ? createdNeedData.roles.map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                };
              })
            : [];
        addNeed({
          id: createdNeedData.id,
          name: createdNeedData.name,
          roles: roles,
        });
        notification.success({
          message: `Потребность успешно создана`,
        });
      }
      if (id !== undefined && !isCreationMode) {
        await changeExistsNeed({
          data: {
            name: data.title,
            roles: mappedRolesToServerRequest,
          },
        });
        changeNeed({
          id: +id,
          name: data.title,
          roles: data.roles.map((item) => {
            return { id: item.key, name: item.label };
          }),
        });
        notification.success({
          message: `Потребность успешно изменена`,
        });
      }
      navigate(NAVIGATION.needs);
    } catch (e) {
      if (data.title.length < 5) {
        notification.error({ message: 'Название должно состоять как минимум из 5 символов' });
        return;
      }
      notification.error({ message: 'Произошла непредвиденная ошибка' });
    }
  };

  const initialData = useMemo(() => {
    const baseData = {
      title: '',
      roles: [],
    };
    if ((!isNeedLoading && !need?.data) || isCreationMode) {
      return baseData;
    }

    const mappedRoles = need?.data.roles?.map((item) => {
      return { key: item.id, value: item.id, label: item.name };
    });
    return {
      title: need?.data.name ?? '',
      roles: mappedRoles ?? [],
    };
  }, [isCreationMode, isNeedLoading, need?.data]);

  if (error) {
    return <ClientErrorException errorCode={error.request.status} />;
  }

  return (
    <NeedContent
      id={id}
      isLoading={isNeedLoading}
      onArchive={archivePlace}
      onSubmit={onFormFinish}
      initialValues={initialData}
    />
  );
};
