import { notification } from 'antd';
import useAxios from 'axios-hooks';
import {
  createPushEndpointUrl,
  deletePushEndpointUrl,
  getPushByIdEndpointUrl,
  updatePushEndpointUrl,
} from 'client/endpoints/pushes';
import { defaultPushItem } from 'pages/Push/constants';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mapPushForFrontend, preparePushForBackend } from 'client/mappers/push';
import { AdminGetOneNotificationResponse } from 'models';
import { NAVIGATION } from 'paths';
import { PushBaseInfoFormValues } from 'pages/Push/types';

type Props = {
  isCreationMode: boolean;
  id?: string;
};

export const usePushesBackendActions = ({ isCreationMode, id }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [{ data, error, loading }] = useAxios<BaseResponse<AdminGetOneNotificationResponse>>(
    getPushByIdEndpointUrl(id),
    {
      manual: isCreationMode,
    },
  );

  const deletePush = useAxios(deletePushEndpointUrl(id), { manual: true })[1];

  const updatePushData = useAxios(updatePushEndpointUrl(id), { manual: true })[1];

  const savePushData = useAxios(createPushEndpointUrl(), { manual: true })[1];

  const pushData = useMemo(() => {
    if (isCreationMode) {
      return defaultPushItem;
    } else if (data !== undefined) {
      return mapPushForFrontend(data.data);
    }
  }, [isCreationMode, data]);

  const handleDeleteNew = useCallback(async () => {
    setIsLoading(true);
    try {
      await deletePush();
      notification.success({
        message: `Пуш "${pushData?.title}" удален`,
      });
      navigate(NAVIGATION.authors);
    } catch (error) {
      notification.error({ message: `Извините, произошла ошибка` });
      setIsLoading(false);
    }
  }, [deletePush, navigate, pushData?.title]);

  const onSave = useCallback(
    async (formFields: PushBaseInfoFormValues) => {
      const pushForSave = preparePushForBackend(formFields);

      try {
        setIsLoading(true);
        const query = isCreationMode ? savePushData : updatePushData;

        await query({ data: pushForSave });

        notification.success({ message: isCreationMode ? 'Пуш создан' : 'Пуш обновлен' });
        navigate(NAVIGATION.pushes);
        setIsLoading(false);
      } catch (error) {
        notification.error({ message: 'Извините, при сохранении пуша произошла ошибка' });
        setIsLoading(false);
      }
    },
    [isCreationMode, navigate, savePushData, updatePushData],
  );

  return useMemo(
    () => ({
      info: {
        data,
        error,
        loading,
        isLoading,
        pushData,
      },
      actions: {
        handleDeleteNew: handleDeleteNew,
        onSave,
      },
    }),
    [data, error, handleDeleteNew, isLoading, loading, pushData, onSave],
  );
};
