import { Checkbox, Layout, Pagination, Row, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { getVideosEndpointUrl } from 'client/endpoints/videos';
import { ListCard } from 'components/ListCard';
import { NotFoundSearchResults } from 'components/NotFoundSearchResults';
import { PageTitle } from 'components/PageTitle';
import { VIDEOS_PLATFORMS } from 'constants/index';
import { useDebounce, useListing } from 'hooks';
import { AdminGetListVideosResponse } from 'models';
import { NAVIGATION } from 'paths';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import { StyledInput } from 'components/StyledInputs/StyledInput';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const checkBoxes = [
  { label: VIDEOS_PLATFORMS['VK'], key: 'vk', value: 'VK' },
  {
    label: VIDEOS_PLATFORMS['YOUTUBE'],
    key: 'youtube',
    value: 'YOUTUBE',
  },
];

export const Videos = () => {
  const push = useNavigate();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const [checkedFilter, setCheckedFilter] = useState<string | null>(null);

  const { data, paginationProps, loading } = useListing<AdminGetListVideosResponse>(
    getVideosEndpointUrl(debouncedSearchValue, checkedFilter),
  );

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const changeSelectedCheckbox = (e: CheckboxChangeEvent) => {
    if (e.target.value === checkedFilter) {
      setCheckedFilter(null);
      return;
    }
    setCheckedFilter(e.target.value);
  };

  return (
    <div>
      <PageTitle title="Видео" wrapperClassName="mb-24" />
      <Layout>
        <Content>
          <div className="p-24 mt-40 bg-neutral mb-24 rounded-8">
            <StyledInput value={searchValue} placeholder="Название сервиса" onChange={handleSearchInputChange} />
            <div className="mt-24">
              {checkBoxes.map((item) => {
                return (
                  <Checkbox
                    name={item.value}
                    key={item.key}
                    checked={item.value === checkedFilter}
                    value={item.value}
                    onChange={changeSelectedCheckbox}
                  >
                    {item.label}
                  </Checkbox>
                );
              })}
            </div>
          </div>
          <Row className="gap-16" justify={loading ? 'center' : undefined}>
            {data && data.data.length < 1 && !loading && <NotFoundSearchResults />}
            {data === undefined || loading ? (
              <Spin className="flex-justify-center" size="large" />
            ) : (
              data.data.map(({ id, title, image, platform, isHidden }) => (
                <ListCard
                  key={id}
                  imageUrl={image ?? undefined}
                  title={title || ''}
                  onClick={() => push(`${NAVIGATION.video}/${id}`)}
                  footerDescription={
                    <div className={styles.wrapper}>
                      <p>{VIDEOS_PLATFORMS[platform] || platform}</p>
                      <div className={`${styles.status} ${isHidden ? styles.offline : ''}`} />
                    </div>
                  }
                  withFooterSeparator
                />
              ))
            )}
          </Row>
          {data?.meta.total ? (
            <div className="d-flex flex-justify-end">
              <Pagination
                {...paginationProps}
                total={data.meta.total}
                pageSizeOptions={[8, 12, 16, 20]}
                defaultCurrent={paginationProps.current}
              />
            </div>
          ) : null}
        </Content>
      </Layout>
    </div>
  );
};
