import { AdminGetOneNotificationResponse } from 'models';
import moment from 'moment';
import { PUSH_ENTITIES_KEYS } from 'pages/Push/forms/PushBaseInfoForm/constants';
import { PushBaseInfoFormValues } from 'pages/Push/types';

export const preparePushForBackend = (pushFields: PushBaseInfoFormValues) => {
  const { body, areasIds, date, entityId, entityType, rolesIds, time, title } = pushFields;

  return {
    body,
    entityType,
    title,
    rolesIds: rolesIds.map((item) => item.key),
    entityId: entityId?.key,
    date: date?.format('YYYY-MM-DD'),
    time: time?.format('HH:mm'),
    areasIds: areasIds.map((id) => {
      return id;
    }),
  };
};

export const mapPushForFrontend = (pushItem: AdminGetOneNotificationResponse): PushBaseInfoFormValues => {
  const { body, date, entity, entityType, roles, time, title, areas } = pushItem;

  const formattedDate = moment(date);

  const formattedTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm');

  return {
    body,
    date: formattedDate,
    entityId: entity ? { value: entity.id, label: entity.name, key: entity.id } : undefined,
    entityType: entityType ? (entityType as PUSH_ENTITIES_KEYS) : undefined,
    rolesIds: roles.map((role) => {
      return {
        label: role.name,
        key: role.id,
        value: role.id,
      };
    }),
    time: formattedTime,
    title,
    areasIds: areas.map((item) => item.id),
    areasNames: areas.map((area) => {
      return {
        label: area.name,
        key: area.id,
        value: area.id,
      };
    }),
  };
};
