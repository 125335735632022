import { Form } from 'antd';
import { ClientNeed, useSocialNeeds } from 'client/hooks/needs';
import { DebouncedSelect } from 'components/DebouncedSelect';
import { FormList } from 'components/FormList';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { AdminCreateNeedDataResponseDto, AdminCreateNeedResponseDto } from 'models';
import React, { FC } from 'react';
import { BASE_NEEDS_SEARCH_URL } from 'utils';
import { LabelKeyValueType } from 'utils/types';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export type NeedsFormInitialValues = {
  initialValues?: { needs?: Array<LabelKeyValueType> };
  isActiveForm?: boolean;
};

const needsMapper = (data: Array<AdminCreateNeedDataResponseDto['data'] | ClientNeed>) => {
  return data.map((item) => {
    return {
      label: item.name,
      key: item.id,
      value: item.name,
    };
  });
};

/**
 *
 * @param isActiveForm - Определяет, является ли текущая форма активной (открыт ли таб, в котором она находится)
 */

export const NeedsForm: FC<NeedsFormInitialValues> = ({ initialValues, isActiveForm = true }) => {
  const [form] = Form.useForm<NeedsFormInitialValues['initialValues']>();

  const { needs: defaultNeeds } = useSocialNeeds(isActiveForm);
  const options = needsMapper(defaultNeeds);

  const { fetcher } = useAsyncSelectOptions<AdminCreateNeedResponseDto>(BASE_NEEDS_SEARCH_URL, needsMapper);
  const defaultValue = { value: '', label: '' };

  return (
    <Form name="needsForm" {...layout} form={form} initialValues={initialValues}>
      <FormList
        label="Выбранные"
        name="needs"
        defaultValue={defaultValue}
        customController={<DebouncedSelect fetcher={fetcher} initialOptions={options} labelInValue={true} />}
      />
    </Form>
  );
};
