import { Forms } from 'rc-field-form/lib/FormContext';

export const getTextEditorValidationError = (
  forms: Forms,
  constantValue: number,
  fieldValue = 'description',
  formName = 'base-info',
) => {
  const descriptionText = forms[formName].getFieldValue(fieldValue);
  return descriptionText.length > constantValue;
};
