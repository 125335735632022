import { Form } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { DebouncedSelect } from 'components/DebouncedSelect';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { EventsSearchListResponse, EventsSearchResponse } from 'models';
import { BASE_EVENTS_SEARCH_PARENT } from 'utils/constants';

const mapEvents = (data: EventsSearchListResponse['data']) => {
  return data.map((item) => {
    return {
      label: item.title,
      key: item.id,
      value: item.title,
    };
  });
};

export const AsyncGlobalEventsSelect = (
  props: Omit<SelectProps, 'labelInValue' | 'fetcher'> & { required?: boolean },
) => {
  const { fetcher } = useAsyncSelectOptions<EventsSearchResponse>(BASE_EVENTS_SEARCH_PARENT, mapEvents);

  return (
    <Form.Item name="relatedGlobalEvent" label={'Связанное глобальное мероприятие'} required={props.required ?? false}>
      <DebouncedSelect fetcher={fetcher} onSelect={props.onSelect} labelInValue={true} {...props} />
    </Form.Item>
  );
};
