import { TSocialRole } from 'forms/SocialForm/SocialForm';

/**
 * Функция для нормализации социальных сетей перед отправкой на сервер.
 */
export const mapSocialNetworksToApiSocials = <T extends TSocialRole>(socials: Array<T>): Array<T> => {
  const isSocialRoleHasUrl = socials.some((item) => {
    return item.url !== undefined;
  });
  return isSocialRoleHasUrl ? socials.filter((i: TSocialRole) => i.url !== undefined && i.url.length >= 1) : [];
};
