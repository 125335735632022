import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Switch, TimePicker, Typography } from 'antd';
import React from 'react';
import { AROUND_THE_CLOCK, NO_SCHEDULE } from 'utils';

type ScheduleItemProps = {
  name: string;
  title: string;
  onResetButtonClick: (name: string) => void;
  onDayEnableClick: (checked: boolean, name: string) => void;
};

export const timeFormat = 'HH:mm';

export const ScheduleFormItem: React.FC<ScheduleItemProps> = ({
  name,
  title,
  onDayEnableClick,
  onResetButtonClick,
}) => {
  return (
    <Row gutter={24}>
      <Col span={7} className="d-flex">
        <Form.Item className="m-0 flex-self-end" shouldUpdate>
          {({ getFieldValue }) => {
            const hasSwitchInitialValue = getFieldValue(['days', name, 'enabled']) !== undefined;

            const isDisabled = getFieldValue(AROUND_THE_CLOCK) || getFieldValue(NO_SCHEDULE);
            return (
              <div className="d-flex gap-24 flex-items-center">
                <Form.Item
                  className="m-0"
                  valuePropName="checked"
                  name={['days', name, 'enabled']}
                  initialValue={hasSwitchInitialValue ? undefined : false}
                >
                  <Switch disabled={isDisabled} onChange={(checked) => onDayEnableClick(checked, name)} />
                </Form.Item>
                <Typography.Title className="m-0" level={4}>
                  {title}
                </Typography.Title>
              </div>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item shouldUpdate label="Работает с / по" className="m-0">
          {({ getFieldValue }) => {
            const isDisabled = !getFieldValue(['days', name, 'enabled']) || getFieldValue(AROUND_THE_CLOCK);

            return (
              <Form.Item name={['days', name, 'hours']} className="m-0">
                <TimePicker.RangePicker format={timeFormat} disabled={isDisabled} />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item className="m-0" shouldUpdate label="Перерыв с / по">
          {({ getFieldValue }) => {
            const isDisabled = !getFieldValue(['days', name, 'enabled']) || getFieldValue(AROUND_THE_CLOCK);

            return (
              <Form.Item name={['days', name, 'break']} className="m-0">
                <TimePicker.RangePicker allowClear format={timeFormat} disabled={isDisabled} />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={3} className="d-flex gap-8 flex-items-end">
        <Form.Item className="m-0" shouldUpdate>
          {({ getFieldValue }) => {
            const isDisabled = !getFieldValue(['days', name, 'enabled']);
            return (
              <Form.Item className="m-0">
                <Button
                  type="text"
                  disabled={isDisabled}
                  className="text-red-6 "
                  icon={<CloseOutlined />}
                  onClick={() => onResetButtonClick(name)}
                >
                  Сбросить
                </Button>
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
};
