import { Form, Select } from 'antd';
import { VIDEOS_QUALITY } from '../../../../constants';
import React from 'react';
import { StyledInputNumber } from 'components/StyledInputs/StyledInputNumber';
import { VideoItemBaseInfoFormValues } from 'pages/Video/types';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

type VideoItemBaseInfoFormProps = { initialValues: VideoItemBaseInfoFormValues };
const qualityList = Object.keys(VIDEOS_QUALITY).map((key) => ({
  label: VIDEOS_QUALITY[key as keyof typeof VIDEOS_QUALITY],
  value: key,
}));

export const VideoItemBaseInfoForm: React.FC<VideoItemBaseInfoFormProps> = ({ initialValues }) => {
  const [form] = Form.useForm<VideoItemBaseInfoFormValues>();

  return (
    <Form form={form} initialValues={initialValues} {...layout} name="base-info">
      <Form.Item name="isHidden" label="Статус" rules={[{ required: true }]}>
        <Select
          options={[
            { label: 'Активен', value: false },
            { label: 'Не активен', value: true },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Приоритет"
        name="priority"
        rules={[{ required: true, message: 'Пожалуйста, введите Приоритет' }]}
      >
        <Form.Item name="priority" className="mb-8">
          <StyledInputNumber max={30} min={1} maxWidth />
        </Form.Item>
        <div className="text-gray-8">Минимальное значение – 1, максимальное – 30</div>
      </Form.Item>
      <Form.Item name="quality" label="Качество" rules={[{ required: true }]}>
        <Select options={qualityList} />
      </Form.Item>
    </Form>
  );
};
