import { axios } from 'client';
import { Index } from 'client/endpoints';
import { UserContext } from 'contexts/UserContext';
import { AuthorVideoResponse } from 'models';
import { useContext } from 'react';
import { useQuery } from 'react-query';

/**
 * @param enabled Флаг, который указывает, нужно ли выполнять запрос на получение видео
 */

export const useVideos = (enabled = true) => {
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';

  const fetchData = async () => {
    const { data } = await axios.get(Index.videos);
    return data.data;
  };

  return useQuery<AuthorVideoResponse[]>(['getVideos', isAuthorized], fetchData, {
    initialData: [],
    enabled: isAuthorized && enabled,
  });
};
