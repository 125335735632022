import { axios } from 'client';
import { Index } from 'client/endpoints';
import { UserContext } from 'contexts/UserContext';
import { AdminAuthorRolesListResponseDto } from 'models';
import { useContext } from 'react';
import { useQuery } from 'react-query';

/**
 * @param enabled Флаг, который указывает, нужно ли выполнять запрос на получение авторов
 */

export const useAuthors = (enabled = true) => {
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';
  const fetchData = async () => {
    const { data } = await axios.get(Index.authors);
    return data.data;
  };

  return useQuery<AdminAuthorRolesListResponseDto[]>(['getAuthors', isAuthorized], fetchData, {
    initialData: [],
    enabled: isAuthorized && enabled,
  });
};
