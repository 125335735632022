import { axios } from 'client';
import { Index } from 'client/endpoints';
import { UserContext } from 'contexts/UserContext';
import { TagDto } from 'models';
import { useContext } from 'react';
import { useQuery } from 'react-query';

export const usePlaceTags = () => {
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';
  const fetchData = async () => {
    if (!isAuthorized) return;
    const { data } = await axios.get(`${Index.places}/tags?limit=20`);
    return data.data;
  };

  return useQuery<TagDto[]>(['getPlaceTags', isAuthorized], fetchData, {
    initialData: [],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
