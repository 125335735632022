import { mapCities } from 'client/mappers/author';
import { AdminGetOneResponse } from 'models';
import { VideoItemBaseInfoFormValues, VideoItemConnectionsFormValues } from 'pages/Video/types';

export const mapVideoForFrontend = (videoData: AdminGetOneResponse) => {
  const { title, isHidden, priority, quality } = videoData;
  return {
    title,
    isHidden,
    priority,
    quality,
    rolesIds: mapCities(videoData.roles) ?? [],
    areasIds: videoData.areas.map((item) => item.id),
    areasNames: mapCities(videoData.areas) ?? [],
    authorId: { label: videoData.author?.name, value: videoData?.author?.id },
  };
};

export const mapBaseVideoInfoForBackend = (videoFields: VideoItemBaseInfoFormValues) => {
  const { isHidden, priority, quality, authorId } = videoFields;

  return {
    isHidden,
    priority,
    quality,
    authorId: authorId?.value ? authorId.value : undefined,
  };
};

export const mapRolesVideoInfoForBackend = (videoFields: VideoItemConnectionsFormValues) => {
  const { rolesIds } = videoFields;

  return {
    rolesIds: rolesIds.map((item) => item.key),
  };
};
