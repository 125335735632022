import { Spin } from 'antd';
import { usePushesBackendActions } from 'client/hooks/pushes';
import { ClientErrorException } from 'components/ClientErrorException';
import { defaultPushItem } from 'pages/Push/constants';
import { PushContent } from 'pages/Push/PushContent';
import React from 'react';
import { useParams } from 'react-router-dom';

export const Push = () => {
  const { id } = useParams<{ id: string }>();
  const isCreationMode = id === 'new';

  const {
    info: { pushData, error, loading, isLoading },
    actions: { onSave },
  } = usePushesBackendActions({ isCreationMode, id });

  if (error) {
    return <ClientErrorException errorCode={error.request.status} />;
  }

  return (
    <Spin spinning={isLoading || loading}>
      {pushData && !loading && <PushContent pushData={isCreationMode ? defaultPushItem : pushData} onSave={onSave} />}
    </Spin>
  );
};
