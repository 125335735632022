import { Form } from 'antd';
import { ClientRole, useSocialRolesList } from 'client/hooks/socialRoles';
import { DebouncedSelect } from 'components/DebouncedSelect';
import { FormList } from 'components/FormList';
import React, { FC } from 'react';
import { LabelKeyValueType } from 'utils/types';
import useAsyncSelectOptions from '../../hooks/useAsyncSelectOptions';
import { RolesListResponse, RolesResponseDto } from '../../models';
import { BASE_ROLES_SEARCH_URL } from '../../utils';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export type LinkedRolesFormProps = {
  initialValues: { socialRoles: Array<LabelKeyValueType> };
  isActiveForm?: boolean;
};

const rolesMapper = (data: RolesListResponse['data'] | ClientRole[]) => {
  return data.map((item) => {
    return {
      label: item.name,
      key: item.id,
      value: item.name,
    };
  });
};

/**
 *
 * @param isActiveForm - Определяет, является ли текущая форма активной (открыт ли таб, в котором она находится).
 */

export const LinkedRolesForm: FC<LinkedRolesFormProps> = ({ initialValues, isActiveForm = true }) => {
  const [form] = Form.useForm<LinkedRolesFormProps['initialValues']>();

  const { roles } = useSocialRolesList(isActiveForm);

  const { fetcher } = useAsyncSelectOptions<RolesResponseDto>(BASE_ROLES_SEARCH_URL, rolesMapper);
  const defaultValue = { value: '', label: '' };
  const mappedRoles = rolesMapper(roles);
  return (
    <Form name="socialRoles" {...layout} form={form} initialValues={initialValues}>
      <FormList
        label="Выбранные"
        name="socialRoles"
        defaultValue={defaultValue}
        customController={<DebouncedSelect fetcher={fetcher} initialOptions={mappedRoles} labelInValue={true} />}
      />
    </Form>
  );
};
