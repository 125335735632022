import { axios } from 'client';
import { Index } from 'client/endpoints';
import { UserContext } from 'contexts/UserContext';
import { useDebounce } from 'hooks';
import { Need } from 'models';
import { useCallback, useContext, useState } from 'react';
import { useQuery } from 'react-query';

export type NeedRole = { name: string; id: number };

export type ChangeNeedType = {
  id: number;
  name: string;
  roles: Array<NeedRole>;
};

export type CreateNeedType = {
  id: number;
  name: string;
  roles: Array<NeedRole>;
};

export type ClientNeed = {
  name: Need['name'];
  id: Need['id'];
  roles: Array<NeedRole>;
};
export type PaginationParams = {
  limit: number;
  offset: number;
};

type NeedEntity = {
  needs: Array<ClientNeed>;
  addNeed: (id: CreateNeedType) => void;
  removeNeed: (id: number) => void;
  changeNeed: (data: ChangeNeedType) => void;
  totalCount: number;
  updatePaginationInfo: (data: PaginationParams) => void;
  updateSearchQuery: (query: string) => void;
  isLoading: boolean;
};

/**
 * @param enabled Флаг, который указывает, нужно ли выполнять запрос на получение потребностей
 */

export const useSocialNeeds = (enabled = true): NeedEntity => {
  const [paginationData, setPaginationData] = useState<PaginationParams>({
    limit: 10,
    offset: 0,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery, 500);
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';
  const searchQueryParam = debouncedQuery.length >= 1 ? `&query=${debouncedQuery}` : '';

  const fetchNeeds = async () => {
    const { data } = await axios.get(
      Index.needs + `?limit=${paginationData.limit}&offset=${paginationData.offset}${searchQueryParam}`,
    );
    return data;
  };

  const {
    data,
    isLoading,
    refetch: getInitialNeeds,
  } = useQuery(['needs', paginationData, debouncedQuery], fetchNeeds, {
    enabled: isAuthorized && enabled,
  });

  const needs = data?.data || [];
  const totalCount = data?.meta.total || 0;

  const updatePaginationInfo = useCallback(({ limit, offset }: PaginationParams) => {
    setPaginationData({ limit, offset });
  }, []);

  const updateSearchQuery = (query: string) => {
    setSearchQuery(query);
  };

  const addNeed = (need: ChangeNeedType) => {
    needs.push(need);
    getInitialNeeds();
  };

  const changeNeed = (need: ChangeNeedType) => {
    const index = needs.findIndex((n: Need) => n.id === need.id);
    if (index !== -1) {
      needs[index] = need;
      getInitialNeeds();
    }
  };

  const removeNeed = (needId: Need['id']) => {
    const index = needs.findIndex((n: Need) => n.id === needId);
    if (index !== -1) {
      needs.splice(index, 1);
      getInitialNeeds();
    }
  };

  return {
    addNeed,
    removeNeed,
    changeNeed,
    needs,
    updatePaginationInfo,
    totalCount,
    updateSearchQuery,
    isLoading,
  };
};
