import useAxios from 'axios-hooks';
import { TagDto } from 'models';
import { Index } from 'client/endpoints';

export type TFormats = { formats: TagDto[]; refetchFormats: () => void };

/**
 * Хук для получения форматов
 * @param enabled Флаг, который указывает, нужно ли выполнять запрос на получение форматов
 */

export const useFormats = (enabled = true): TFormats => {
  const [{ data }, refetchFormats] = useAxios<BaseResponse<TagDto[]>>(
    { url: Index.adminEvents + '/info/formats?limit=20', method: 'GET' },
    { manual: !enabled },
  );

  return { formats: data?.data || [], refetchFormats };
};
