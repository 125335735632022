import { Rule } from 'antd/lib/form';
import { Index } from '../client/endpoints';
import { toPhoneWithoutMask } from './normalizers/toPhoneWithoutMask';
import { phoneRegExp, urlRegExp } from './regExp';

export const MIN_SLICE_SEPARATOR = 4;

export const AROUND_THE_CLOCK = 'aroundTheClock';
export const NO_SCHEDULE = 'noSchedule';

export const FORMAT_LIST = [
  { label: 'Offline', id: 'offline' },
  { label: 'Online', id: 'online' },
];

export const FORM_PHONE_MASKED_INPUT_PROPS = {
  format: '+# (###) ###-##-##',
  mask: '_',
};

export const PHONE_VALIDATOR: Rule = {
  required: true,
  pattern: phoneRegExp,
  message: 'Неправильный номер телефона',
  transform: (value) => {
    return toPhoneWithoutMask(value);
  },
};

export const URL_VALIDATOR: Rule = {
  required: true,
  pattern: urlRegExp,
  message: 'Неправильный формат ссылки',
};

const OFFSET = 0;
const TOTAL_COUNT_10 = 10;

export const BASE_NEEDS_SEARCH_URL = Index.needs + `?limit=${TOTAL_COUNT_10}&offset=${OFFSET}&query=`;

export const BASE_ROLES_SEARCH_URL = Index.roles + `?limit=${TOTAL_COUNT_10}&offset=${OFFSET}&query=`;

export const BASE_NEWS_SEARCH_URL = Index.news + `?limit=${TOTAL_COUNT_10}&offset=${OFFSET}&query=`;

export const BASE_VIDEOS_SEARCH_URL = Index.videos + `/search?limit=${TOTAL_COUNT_10}&offset=${OFFSET}&query=`;

export const BASE_EVENTS_SEARCH_URL = Index.events + `/search?limit=${TOTAL_COUNT_10}&offset=${OFFSET}&query=`;

export const BASE_SERVICES_SEARCH_URL = Index.services + `/search?limit=${TOTAL_COUNT_10}&offset=${OFFSET}&query=`;

export const BASE_PLACES_SEARCH_URL = Index.places + `?limit=${TOTAL_COUNT_10}&offset=${OFFSET}&title=`;

export const BASE_AUTHORS_SEARCH_URL = Index.authors + `?limit=${TOTAL_COUNT_10}&offset=${OFFSET}&query=`;

export const DADATA_BASE_SEARCH_URL = `${Index.addressSuggestions}?query=`;

export const BASE_EVENTS_SEARCH_PARENT =
  Index.adminEvents + `/search-parents?limit=${TOTAL_COUNT_10}&offset=${OFFSET}&query=`;

export const MAX_AMOUNT_OF_IMAGES = 15;
