import { Form, Select, Typography } from 'antd';
import { Index } from 'client/endpoints';
import { useEventTags } from 'client/hooks/events';
import { AgeFormItem } from 'components/AgeFormItem';
import { DebouncedSelect } from 'components/DebouncedSelect';
import { PlaceSwitcher } from 'components/PlaceSwitcher';
import { StyledInput } from 'components/StyledInputs/StyledInput';
import { StyledInputNumber } from 'components/StyledInputs/StyledInputNumber';
import { StyledTextArea } from 'components/StyledInputs/StyledTextArea';
import { StyledSelectComponent } from 'components/StyledSelect';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { RolesResponseDto } from 'models';
import { AsyncGlobalEventsSelect } from 'pages/Event/components/AsyncGlobalEventsSelect';
import { EventTypeSelect } from 'pages/Event/components/EventTypeSelect';
import React, { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FORMAT_LIST, translatePeriodicity } from 'utils';
import { mapDadataResponse } from 'utils/formatters/mapDadataResponse';
import { LabelKeyValueType } from 'utils/types';
import { BaseInfoEventFormValues } from './BaseEventForm.typedef';

const { Option } = Select;
const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 },
};

type BaseEventFormProps = {
  initialValues: BaseInfoEventFormValues;
  id: string;
};

export const BaseEventForm: FC<BaseEventFormProps> = ({ initialValues }) => {
  const [form] = Form.useForm<BaseInfoEventFormValues>();
  const [currentFormat, setCurrentFormat] = useState<BaseInfoEventFormValues['format']>(initialValues.format);
  const translatedPeriodicity = translatePeriodicity(initialValues.status);
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');
  const { data: eventTags } = useEventTags();

  const changeFormat = (format: BaseInfoEventFormValues['format']) => {
    form.setFieldValue('format', format);
    setCurrentFormat(format);
  };
  const selectOption = (option: LabelKeyValueType) => {
    form.setFieldValue('relatedGlobalEvent', option);
  };

  const isEventParentSelectAvailable =
    initialValues.frequency !== null && status !== 'global' && initialValues.status !== 'global';

  const { fetcher } = useAsyncSelectOptions<RolesResponseDto>(
    Index.adminEvents + '/tags/search?query=',
    mapDadataResponse,
  );

  return (
    <Form form={form} {...layout} name="baseInformationForm" initialValues={initialValues}>
      <div className="d-flex flex-justify-center mb-40">
        <Typography>
          Общие сведения: {initialValues.fullTitle} ({translatedPeriodicity} событие){' '}
        </Typography>
      </div>
      {isEventParentSelectAvailable && (
        <>
          <AsyncGlobalEventsSelect allowClear onSelect={selectOption} />
          <div className="d-flex flex-justify-end">
            <p style={{ width: 500, textAlign: 'left' }}>
              <span className="text-red-6 text-left">* </span>
              Локальное событие может выступать в качестве связанного (зависимого события), так и в качестве
              самостоятельного. Если мероприятие относится к некоторому глобальному событию – укажите его в поле выше
            </p>
          </div>
        </>
      )}
      <Form.Item name="shortTitle" label="Заголовок (наименование)" rules={[{ required: true }]}>
        <StyledInput />
      </Form.Item>
      <Form.Item
        label="Приоритет"
        name="priority"
        rules={[{ required: true, message: 'Пожалуйста, введите Приоритет' }]}
      >
        <Form.Item name="priority">
          <StyledInputNumber max={30} min={1} maxWidth />
        </Form.Item>
        <div className="text-gray-8 mt-16">Минимальное значение – 1, максимальное – 30</div>
      </Form.Item>
      <Form.Item name="fullTitle" label="Полное наименование" rules={[{ required: true }]}>
        <StyledInput.TextArea />
      </Form.Item>
      <Form.Item name="alternativeTitle" label="Альтернативное наименование" rules={[{ required: false }]}>
        <StyledInput />
      </Form.Item>
      <AgeFormItem formItemProps={{ name: 'minAge', rules: [{ required: true }] }} />
      <Form.Item name="format" label="Формат проведения" rules={[{ required: true }]}>
        <StyledSelectComponent onChange={changeFormat} value={form.getFieldValue('format') ?? FORMAT_LIST[0].id}>
          {FORMAT_LIST.map((item) => {
            return (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            );
          })}
        </StyledSelectComponent>
      </Form.Item>
      <EventTypeSelect allowClear required={true} />
      <Form.Item name="tags" label="Теги">
        <DebouncedSelect
          fetcher={fetcher}
          initialOptions={mapDadataResponse(eventTags)}
          labelInValue={true}
          mode="multiple"
        />
        {/* <StyledSelectComponent options={mapDadataResponse(eventTags)} mode="multiple" onClick={refetchEventTags} /> */}
      </Form.Item>
      {currentFormat === 'offline' && status !== 'global' && initialValues.status !== 'global' && (
        <PlaceSwitcher
          initialParent={initialValues.place}
          selectFormItemName={'place'}
          initialTab={initialValues.place ? 'place' : 'address'}
          enableCitySelect={false}
        />
      )}
      <Form.Item
        name="avgTime"
        label="Среднее время посещения (мин)"
        rules={[{ required: false, min: 1, type: 'number' }]}
      >
        <StyledInputNumber />
      </Form.Item>
      <Form.Item name="shortDescription" label="Короткое описание" rules={[{ required: true, min: 5 }]}>
        <StyledInput />
      </Form.Item>
      <Form.Item name="fullDescription" label="Описание события" rules={[{ required: true, min: 5 }]}>
        <StyledTextArea />
      </Form.Item>
    </Form>
  );
};
