import { AdminNewsResponseDto, NewsDto } from 'models';
import { FrontendNewItem } from 'pages/NewItem/types';
import { rolesMapper } from '../author';

export const getNewData = (): FrontendNewItem => ({
  id: NaN,
  title: '',
  announce: '',
  body: '',
  isPinned: false,
  priority: 1,
  socialRoles: [],
  image: null,
});

export const mapNewForFrontend = (data: AdminNewsResponseDto): FrontendNewItem => {
  const { id, title, priority, announce, body, isPinned, image, areas } = data;
  return {
    id,
    title,
    priority,
    announce,
    body,
    sourceUrl: data.sourceUrl || undefined,
    isPinned,
    socialRoles: data.roles ? rolesMapper(data.roles) : undefined,
    image,
    area: areas.length === 1 ? { value: areas[0].id, label: areas[0].name, key: areas[0].id } : undefined,
  };
};

export const mapNewForBackend = (formFields: FrontendNewItem): NewsDto => {
  const { title, priority, announce, body, isPinned, sourceUrl, area } = formFields;
  return {
    title,
    priority,
    isPinned,
    roles: formFields.socialRoles?.map((role) => {
      return role.key;
    }),
    announce,
    sourceUrl,
    areas: area ? [area.key] : undefined,
    isAllAreas: !area,
    body,
  };
};
