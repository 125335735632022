import { Form } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ClientRole } from 'client/hooks/socialRoles';
import { DebouncedSelect } from 'components/DebouncedSelect';
import { FormList } from 'components/FormList';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { Role, RolesListResponse, RolesResponseDto } from 'models';
import React, { FC } from 'react';
import { BASE_ROLES_SEARCH_URL } from 'utils';
import { LabelKeyValueType } from 'utils/types';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export type LinkedRolesFormProps = {
  initialValues: { socialRoles?: Array<LabelKeyValueType> };
  roles?: DefaultOptionType[];
};

const rolesMapper = (data?: RolesListResponse['data'] | ClientRole[] | Role[]) => {
  return data?.map((item) => {
    return {
      label: item.name,
      key: item.id,
      value: item.name,
    };
  });
};
export const NewItemRolesForm: FC<LinkedRolesFormProps> = ({ initialValues, roles }) => {
  const [form] = Form.useForm<LinkedRolesFormProps['initialValues']>();

  const { fetcher } = useAsyncSelectOptions<RolesResponseDto>(BASE_ROLES_SEARCH_URL, rolesMapper);
  const defaultValue = { value: '', label: '' };

  return (
    <Form name="socialRoles" {...layout} form={form} initialValues={initialValues}>
      <FormList
        label="Выбранные"
        name="socialRoles"
        defaultValue={defaultValue}
        customController={<DebouncedSelect fetcher={fetcher} initialOptions={roles} labelInValue={true} />}
      />
    </Form>
  );
};
