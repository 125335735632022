import { Col, DatePicker, Form, Row } from 'antd';
import { ScheduleFormItem } from 'forms/ScheduleForm/ScheduleFormItem';
import { ScheduleFormSettings } from 'forms/ScheduleForm/ScheduleFormSettings';
import { Moment } from 'moment/moment';
import React, { FC, useState } from 'react';
import {
  AROUND_THE_CLOCK,
  DateCortege,
  getFormattedDateWithDayAndMonth,
  getFullDay,
  getShortDateWithEnglishLocale,
  isMomentCortege,
  NO_SCHEDULE,
} from 'utils';

export type DatesAndTicketsValues = {
  [AROUND_THE_CLOCK]: boolean;
  [NO_SCHEDULE]: boolean;
  days: {
    [day: string]: {
      enabled: boolean;
      hours?: DateCortege;
      break?: DateCortege;
    };
  };
};
export type DatesAndTicketsProps = {
  date?: DateCortege;
  initialValues: DatesAndTicketsValues;
};

export const DatesAndTicketsForm: FC<DatesAndTicketsProps> = ({ initialValues, date }) => {
  const [formSchedule] = Form.useForm<DatesAndTicketsValues>();
  const [selectedDate, setSelectedDate] = useState(isMomentCortege(date) ? date[0] : undefined);

  const handleResetButtonClick = (day: string) => {
    formSchedule.setFields([
      { name: ['days', day, 'hours'], value: [undefined, undefined] },
      { name: ['days', day, 'break'], value: [undefined, undefined] },
    ]);
  };

  const toggleDaysSchedule = (enabled: boolean, isFulldaySchedule?: boolean) => {
    const days = formSchedule.getFieldValue('days');
    const newDays: DatesAndTicketsValues['days'] = {};

    for (const key in days) {
      newDays[key] = {
        enabled,
        break: [undefined, undefined],
        hours: isFulldaySchedule && enabled ? [getFullDay(), getFullDay()] : [undefined, undefined],
      };
    }

    formSchedule.setFieldValue('days', newDays);
  };

  const handleAllTimeButtonChange = (checked: boolean) => {
    toggleDaysSchedule(checked, true);
    if (checked) {
      formSchedule.setFieldValue(NO_SCHEDULE, false);
    }
  };

  const handleDayClick = (checked: boolean, day: string) => {
    if (!checked) {
      handleResetButtonClick(day);
    }
  };

  const changeDatePickerDate = (value: Moment | null) => {
    if (formSchedule.getFieldValue(AROUND_THE_CLOCK)) {
      if (value) {
        formSchedule.setFieldValue(['days', getShortDateWithEnglishLocale(value?.toDate()), 'enabled'], true);
        toggleDaysSchedule(true, true);
      }
    }
    if (value) setSelectedDate(value);
  };

  const disabledDate = (current: Moment) => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    today.setHours(23, 59, 59, 999);

    const todayPlusThreeYears = new Date(new Date().setFullYear(new Date().getFullYear() + 3));

    return current && (current.toDate() < today || current.toDate() > todayPlusThreeYears);
  };

  return (
    <Form layout="vertical" name="scheduleForm" initialValues={initialValues} form={formSchedule}>
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item label="График">
            <DatePicker disabledDate={disabledDate} onChange={changeDatePickerDate} value={selectedDate} />
            {/*<RangePicker allowClear value={dates} onChange={changeDatePickerDate} disabledDate={disabledDate} />*/}
          </Form.Item>
        </Col>
        <Col span={19} className="d-flex flex-column gap-16 flex-justify-center flex-items-center">
          <Form.Item shouldUpdate className="mb-0">
            {() => {
              if (selectedDate) {
                const title = getFormattedDateWithDayAndMonth(selectedDate?.toDate());
                return (
                  <>
                    <ScheduleFormSettings aroundTheClockButtonClick={handleAllTimeButtonChange} />
                    <div className="mt-24">
                      <ScheduleFormItem
                        title={title}
                        onDayEnableClick={handleDayClick}
                        name={getShortDateWithEnglishLocale(selectedDate?.toDate())}
                        onResetButtonClick={handleResetButtonClick}
                      />
                    </div>
                  </>
                );
              }
            }}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
