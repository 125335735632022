import { axios } from 'client';
import { getCitiesEndpointUrl } from 'client/endpoints/cities';
import { UserContext } from 'contexts/UserContext';
import { AdminCitiesAreasResponseDto } from 'models';
import { useContext } from 'react';
import { useQuery } from 'react-query';

/**
 *
 * @param enabled Флаг, который указывает, нужно ли выполнять запрос на получение городов
 *
 */

export const useCitiesEntity = (enabled = true) => {
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';

  const fetchData = async () => {
    const { data } = await axios.get(getCitiesEndpointUrl());
    return data.data;
  };

  return useQuery<AdminCitiesAreasResponseDto[]>(['getCities', isAuthorized], fetchData, {
    initialData: [],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isAuthorized && enabled,
  });
};
