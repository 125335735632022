import { ConfigProvider } from 'antd';
import ruLocale from 'antd/lib/locale-provider/ru_RU';
import { Header } from 'components/Header';
import { UserContext, UserContextProvider } from 'contexts/UserContext';
import 'moment/locale/ru';
import { Cities } from 'pages/Cities';
import { Feedback } from 'pages/Feedback';
import { News } from 'pages/News';
import { Place } from 'pages/Place';
import { Places } from 'pages/Places';
import { Push } from 'pages/Push';
import { Service } from 'pages/Service';
import { Services } from 'pages/Services';
import { Tags } from 'pages/Tags';
import { VideoItem } from 'pages/Video';
import { Videos } from 'pages/Videos';
import { NAVIGATION } from 'paths';
import React, { useContext } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Author } from './pages/Author';
import { Authors } from './pages/Authors';
import { City } from './pages/City';
import { Event } from './pages/Event';
import { Events } from './pages/Events';
import { FormatsAndThematics } from './pages/FormatsAndThematics';
import { Need } from './pages/Need';
import { Needs } from './pages/Needs';
import { NewItem } from './pages/NewItem';
import { Pushes } from './pages/Pushes';
import { SocialRole } from './pages/SocialRole';
import { SocialRoles } from './pages/SocialRoles';
import { AuthorizeForm } from 'forms/AuthorizeForm';

const queryClient = new QueryClient();

const Router = () => {
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';

  return (
    <Routes>
      <Route path={NAVIGATION.authorization} element={<AuthorizeForm />} />
      {isAuthorized && (
        <>
          <Route path={NAVIGATION.places} element={<Places />} />
          <Route path={`${NAVIGATION.places}/:id`} element={<Place />} />
          <Route path={NAVIGATION.services} element={<Services />} />
          <Route path={`${NAVIGATION.services}/:id`} element={<Service />} />
          <Route path={`${NAVIGATION.events}`} element={<Events />} />
          <Route path={`${NAVIGATION.events}/:id`} element={<Event />} />
          <Route path={`${NAVIGATION.socialRoles}`} element={<SocialRoles />} />
          <Route path={`${NAVIGATION.socialRoles}/:id`} element={<SocialRole />} />
          <Route path={`${NAVIGATION.needs}`} element={<Needs />} />
          <Route path={`${NAVIGATION.needs}/:id`} element={<Need />} />
          <Route path={`${NAVIGATION.tags}/`} element={<Tags />} />
          <Route path={NAVIGATION.news} element={<News />} />
          <Route path={`${NAVIGATION.news}/:id`} element={<NewItem />} />
          <Route path={`${NAVIGATION.cities}`} element={<Cities />} />
          <Route path={`${NAVIGATION.cities}/:id`} element={<City />} />
          <Route path={`${NAVIGATION.formatsAndThematics}`} element={<FormatsAndThematics />} />
          <Route path={`${NAVIGATION.authors}`} element={<Authors />} />
          <Route path={`${NAVIGATION.authors}/:id`} element={<Author />} />
          <Route path={`${NAVIGATION.pushes}`} element={<Pushes />} />
          <Route path={`${NAVIGATION.pushes}/:id`} element={<Push />} />
          <Route path={`${NAVIGATION.feedback}`} element={<Feedback />} />
          <Route path={`${NAVIGATION.video}`} element={<Videos />} />
          <Route path={`${NAVIGATION.video}/:id`} element={<VideoItem />} />
        </>
      )}
      <Route path="*" element={<Navigate to={isAuthorized ? NAVIGATION.news : NAVIGATION.authorization} />} />
    </Routes>
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={ruLocale}>
        <BrowserRouter>
          <UserContextProvider>
            <Header />
            <Router />
          </UserContextProvider>
        </BrowserRouter>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
