import React, { useMemo, useState } from 'react';
import { Form, Layout, notification } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { EntityHeader } from 'components/EntityHeader';
import { FormHeader } from 'components/FormHeader';
import { Gallery } from 'components/Gallery';
import { SocialRolesForm } from 'forms/SocialRolesForm';
import { useTabs } from 'hooks';
import { placeTabKeys, placeTabs, TAB_PREFIX } from 'hooks/useTabs';
import { ImageEntity, ImageType } from '../../hooks/useUploadImage';
import { AROUND_THE_CLOCK, NO_SCHEDULE } from '../../utils';
import { FormProviderProps } from 'antd/lib/form/context';
import { useSearchParams } from 'react-router-dom';
import { TabsWithButtons } from '../../components/TabsWithButtons/TabsWithButtons';
import { showFormErrorMessages } from '../../utils/showFormErrorMessages';
import { PlaceFrontend } from 'client/mappers/place';
import { ContactsForm } from 'forms/ContactsForm';
import { PlaceBaseInfoForm, PlaceBaseInfoFormValues } from './forms/PlaceBaseInfoForm/PlaceBaseInfoForm';
import { OurReviewForm } from 'forms/OurReviewForm';
import { DatesAndTicketsValues } from 'pages/Event/forms/DatesAndTicketsForm';
import { ScheduleForm } from 'forms/ScheduleForm';
import { ParentPlace } from './components/ParentPlace';
import { RelatedPlaces } from './components/RelatedPlaces';
import { useSocialRoles } from 'client/hooks/socialRoles';

type PlaceContentProps = {
  place: PlaceFrontend;
  onDeleteButtonClick: () => void;
  onSave: (placeForSave: PlaceFrontend & DatesAndTicketsValues) => void;
};

const getTabs = (isNewPlace: boolean) => {
  if (isNewPlace) {
    return placeTabs.filter(({ key }) => ![placeTabKeys.images, placeTabKeys.connections].includes(key));
  } else {
    return placeTabs;
  }
};

export const PlaceContent: React.FC<PlaceContentProps> = ({ place, onSave, onDeleteButtonClick }) => {
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get(TAB_PREFIX)?.toString();
  const isNewPlace = isNaN(place.id);
  const { activeTab, tabs, setActiveTab, goToNextTab, goToPrevTab } = useTabs(getTabs(isNewPlace), initialTab);
  const needsProps = useSocialRoles(place.needs);
  const [favoriteImage, setFavoriteImage] = useState(place.image);

  const baseInfoFormInitialValues: PlaceBaseInfoFormValues = useMemo(() => {
    return {
      lat: place.lat,
      lon: place.lon,
      type: place.type,
      tags: place.tags,
      title: place.title,
      parent: place.parent,
      history: place.history,
      address: place.address,
      altTitle: place.altTitle,
      fullTitle: place.fullTitle,
      categories: place.categories,
      description: place.description,
      visitingAge: place.visitingAge,
      targetGroups: place.targetGroups,
      clarification: place.clarification,
      shortDescription: place.shortDescription,
      visitTimeAverage: place.visitTimeAverage,
      area: place.area,
      priority: place.priority,
    };
  }, [place]);

  const contactsFormInitialValues = useMemo(
    () => ({
      sites: place.sites,
      emails: place.emails,
      phones: place.phones,
      socials: place.socials,
      legalAddress: place.legalAddress ?? '',
    }),
    [place],
  );

  const scheduleInitialValues = useMemo(
    () => ({
      days: place.schedule,
      [NO_SCHEDULE]: place[NO_SCHEDULE],
      [AROUND_THE_CLOCK]: place[AROUND_THE_CLOCK],
    }),
    [place],
  );

  const tabContent = {
    [placeTabKeys.common]: <PlaceBaseInfoForm initialValues={baseInfoFormInitialValues} currentPlaceId={place.id} />,
    [placeTabKeys.workTime]: <ScheduleForm initialValues={scheduleInitialValues} />,
    [placeTabKeys.images]: (
      <Gallery
        title="Галерея места"
        gallery={place.gallery}
        entity={ImageEntity.Places}
        favoriteImage={favoriteImage}
        imageType={ImageType.Gallery}
        onStarClick={setFavoriteImage}
      />
    ),
    [placeTabKeys.roles]: <SocialRolesForm isActiveForm={activeTab === placeTabKeys.roles} {...needsProps} />,
    [placeTabKeys.review]: <OurReviewForm initialValue={place?.review} />,
    [placeTabKeys.contacts]: <ContactsForm initialValues={contactsFormInitialValues} />,
    [placeTabKeys.connections]: place.parent ? (
      <ParentPlace {...place.parent} />
    ) : (
      <RelatedPlaces isActive={activeTab === placeTabKeys.connections} id={place.id} />
    ),
  };

  const onFormFinish: FormProviderProps['onFormFinish'] = async (_, { forms }) => {
    const errorMessage = () => notification.error({ message: 'Необходимо заполнить все поля корректно' });
    try {
      const validation = await Promise.allSettled(Object.values(forms).map((f) => f.validateFields()));

      const hasInvalidValues = validation.find((v) => v.status === 'rejected');

      if (!hasInvalidValues) {
        const data: Record<string, unknown> = {
          image: favoriteImage,
          needs: needsProps.needs,
        };

        Object.entries(forms).forEach(([_, form]) => {
          Object.assign(data, form.getFieldsValue());
        });

        onSave(data as PlaceFrontend & DatesAndTicketsValues);
      } else {
        showFormErrorMessages(validation);
      }
    } catch (error) {
      errorMessage();
    }
  };

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Layout>
        <EntityHeader title={place.title || 'Новое место'} onArchiveButtonClick={onDeleteButtonClick} />
        <TabsWithButtons
          filteredTabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onLeftButtonClick={goToPrevTab}
          onRightButtonClick={goToNextTab}
        />
        <Content className="mt-40 p-16 rounded-16 bg-neutral">
          {tabs.map(({ key, label, contentClassName, formHeaderType }) => {
            return (
              <div key={key} className={contentClassName}>
                <FormHeader title={label} type={formHeaderType} />
                <div>{tabContent[key]}</div>
              </div>
            );
          })}
        </Content>
      </Layout>
    </Form.Provider>
  );
};
