import useAxios from 'axios-hooks';
import { useCallback, useEffect, useState } from 'react';

const initialPagination = { current: 1, pageSize: 8 };

export const useListing = <T,>(url: string, pagination = initialPagination, searchQuery = '') => {
  const [paginationProps, setPaginationProps] = useState(pagination);

  const [{ data, loading, error }, getData] = useAxios<BaseResponse<T[], PageMeta>>(
    { method: 'GET' },
    { manual: true },
  );

  useEffect(() => {
    setPaginationProps(pagination);
    let preparedUrl = `${url}&offset=0&limit=${pagination.pageSize}`;

    if (searchQuery.length > 0) preparedUrl = `${preparedUrl}&query=${searchQuery}`;

    getData({
      url: preparedUrl,
    });
  }, [url, getData, searchQuery, pagination]);

  const handlePaginationChange = useCallback(
    (current: number, pageSize: number) => {
      setPaginationProps(({ pageSize: prevPageSize }) => {
        const currentPage = pageSize !== prevPageSize ? 1 : current;
        getData({
          url: `${url}&offset=${currentPage === 1 ? '0' : (current - 1) * pageSize}&limit=${pageSize}`,
        });
        return { current: currentPage, pageSize };
      });
    },
    [setPaginationProps, getData, url],
  );

  return {
    data,
    error,
    loading,
    meta: data?.meta,
    paginationProps: { ...paginationProps, onChange: handlePaginationChange },
    refetch: () =>
      getData({
        url: `${url}&offset=0&limit=${pagination.pageSize}`,
      }),
  };
};
