import React, { FC } from 'react';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Stars } from 'components/Stars';
import { TextEditor } from 'components/TextEditor';
import { StyledSelectComponent } from 'components/StyledSelect';

const ratingValues = [1, 2, 3, 4, 5];

export type TOurReviewForm = Required<{ text: string; score: number }>;

type OurReviewProps = { initialValue?: TOurReviewForm };

export const OurReviewForm: FC<OurReviewProps> = ({ initialValue }) => {
  const [form] = useForm<TOurReviewForm>();
  return (
    <Form form={form} name="review-form" initialValues={{ review: initialValue }}>
      <Form.Item shouldUpdate className="m-0">
        <Form.Item name={['review', 'text']}>
          <TextEditor />
        </Form.Item>
        <Form.Item shouldUpdate className="m-0">
          {(form) => {
            const rating = form.getFieldValue(['review', 'score']);
            return (
              <>
                <div className="mb-8 d-flex gap-8 flex-items-center">
                  Оценка сервиса
                  <div className="d-flex gap-4 ">
                    <Stars filledStars={rating} />
                  </div>
                </div>
                <Form.Item name={['review', 'score']}>
                  <StyledSelectComponent options={ratingValues.map((r) => ({ label: r, value: r }))} />
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
      </Form.Item>
    </Form>
  );
};
