import { axios } from 'client';
import { Index } from 'client/endpoints';
import { baseAuthorBindingMapper, baseBindingMapper } from 'client/mappers/author';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { AdminAuthorResponseDto, AdminNewsResponseDto, AdminServiceListDto } from 'models';
import { useQuery } from 'react-query';
import {
  BASE_AUTHORS_SEARCH_URL,
  BASE_EVENTS_SEARCH_URL,
  BASE_NEWS_SEARCH_URL,
  BASE_PLACES_SEARCH_URL,
  BASE_SERVICES_SEARCH_URL,
} from 'utils/constants';
import { PUSH_ENTITIES_KEYS } from './constants';
import { useNews } from 'client/hooks/news';
import { useServices } from 'client/hooks/services';
import { useEvents } from 'client/hooks/events';
import { usePlaces } from 'client/hooks/places';
import { useAuthors } from 'client/hooks/authors';

export const usePushMapping = (selectedEntity?: PUSH_ENTITIES_KEYS) => {
  const { data: news } = useNews();
  const mappedNews = baseBindingMapper(news);
  const { fetcher: newsFetcher } = useAsyncSelectOptions<AdminNewsResponseDto>(BASE_NEWS_SEARCH_URL, baseBindingMapper);

  const { data: services } = useServices();
  const mappedServices = baseBindingMapper(services);
  const { fetcher: servicesFetcher } = useAsyncSelectOptions<AdminServiceListDto>(
    BASE_SERVICES_SEARCH_URL,
    baseBindingMapper,
  );

  const { data: events } = useEvents();
  const mappedEvents = baseBindingMapper(events);
  const { fetcher: eventsFetcher } = useAsyncSelectOptions<AdminNewsResponseDto>(
    BASE_EVENTS_SEARCH_URL,
    baseBindingMapper,
  );

  const { data: places } = usePlaces();
  const mappedPlaces = baseBindingMapper(places);
  const { fetcher: placesFetcher } = useAsyncSelectOptions<AdminServiceListDto>(
    BASE_PLACES_SEARCH_URL,
    baseBindingMapper,
  );

  const { data: authors } = useAuthors();
  const mappedAuthors = baseAuthorBindingMapper(authors);
  const { fetcher: authorsFetcher } = useAsyncSelectOptions<AdminAuthorResponseDto>(
    BASE_AUTHORS_SEARCH_URL,
    baseAuthorBindingMapper,
  );

  switch (selectedEntity) {
    case PUSH_ENTITIES_KEYS.news:
      return { mappedData: mappedNews, fetcher: newsFetcher };
    case PUSH_ENTITIES_KEYS.events:
      return { mappedData: mappedEvents, fetcher: eventsFetcher };
    case PUSH_ENTITIES_KEYS.places:
      return { mappedData: mappedPlaces, fetcher: placesFetcher };
    case PUSH_ENTITIES_KEYS.services:
      return { mappedData: mappedServices, fetcher: servicesFetcher };
    case PUSH_ENTITIES_KEYS.authors:
      return { mappedData: mappedAuthors, fetcher: authorsFetcher };
    default:
      return { mappedData: mappedNews, fetcher: newsFetcher };
  }
};

export const useGetBusyTime = (date?: string) => {
  const fetchData = async () => {
    if (!date) return;
    const { data } = await axios.get(`${Index.pushes}/busy-time?date=${date}`);
    return data.data;
  };
  return useQuery(['getBusyTime', date], fetchData);
};
