import { axios } from 'client';
import { Index } from 'client/endpoints';
import { UserContext } from 'contexts/UserContext';
import { Place } from 'models';
import { useContext } from 'react';
import { useQuery } from 'react-query';

/**
 * @param enabled Флаг, который указывает, нужно ли выполнять запрос на получение мест
 */

export const usePlaces = (enabled = true) => {
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';
  const fetchData = async () => {
    const { data } = await axios.get(Index.places);
    return data.data;
  };

  return useQuery<Place[]>(['getPlaces', isAuthorized], fetchData, {
    initialData: [],
    enabled: isAuthorized && enabled,
  });
};
