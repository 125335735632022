import { Form, Layout, notification } from 'antd';
import { FormProviderProps } from 'antd/lib/form/context';
import { Content } from 'antd/lib/layout/layout';
import { EntityHeader } from 'components/EntityHeader';
import { FormHeader } from 'components/FormHeader';
import { SocialRolesForm } from 'forms/SocialRolesForm';
import { useTabs } from 'hooks';
import { serviceTabKeys, serviceTabs } from 'hooks/useTabs';
import React, { useMemo } from 'react';
import { getTextEditorValidationError } from 'utils/getTextEditorValidationError';
import { TabsWithButtons } from '../../components/TabsWithButtons/TabsWithButtons';
import { SERVICE_DESCRIPTION_MAX_LENGTH, SERVICE_SUITABLE_FOR_MAX_LENGTH } from '../../constants';
import { ServiceApp } from '../../models';
import { showFormErrorMessages } from '../../utils/showFormErrorMessages';
import { FrontendService, getBaseInfoFormInitialValues, getTechInfoFormInitialValues } from 'client/mappers/service';
import { OurReviewForm } from 'forms/OurReviewForm';
import { BaseInfoForm } from './forms/BaseInfoForm';
import { TechInfoForm } from './forms/TechInfoForm';
import { ServiceImages } from './components/ServiceImages';
import { useSocialRoles } from 'client/hooks/socialRoles';

type TabsContentProps = {
  service: FrontendService;
  onSave: (data: FrontendService) => void;
  onDeleteButtonClick: () => void;
};

const getTabs = (isNewService: boolean) => {
  return isNewService ? serviceTabs.filter(({ key }) => ![serviceTabKeys.images].includes(key)) : serviceTabs;
};

export const ServiceContent: React.FC<TabsContentProps> = ({ onSave, service, onDeleteButtonClick }) => {
  const isNewService = service.id === undefined || isNaN(service.id);
  const { activeTab, tabs, setActiveTab, goToNextTab, goToPrevTab } = useTabs(getTabs(isNewService));
  const needsProps = useSocialRoles(service.needs);

  const { baseInfoFormInitialValues, techInfoFormInitialValues } = useMemo(() => {
    return {
      baseInfoFormInitialValues: getBaseInfoFormInitialValues(service),
      techInfoFormInitialValues: getTechInfoFormInitialValues(service),
    };
  }, [service]);

  const tabContent = {
    [serviceTabKeys.common]: <BaseInfoForm initialValues={baseInfoFormInitialValues} />,
    [serviceTabKeys.tech]: <TechInfoForm initialValues={techInfoFormInitialValues} />,
    [serviceTabKeys.images]: <ServiceImages service={service} />,
    [serviceTabKeys.roles]: <SocialRolesForm isActiveForm={activeTab === serviceTabKeys.roles} {...needsProps} />,
    [serviceTabKeys.review]: <OurReviewForm initialValue={service.review} />,
  };

  const onFormFinish: FormProviderProps['onFormFinish'] = async (_, { forms }) => {
    const errorMessage = () => notification.error({ message: 'Необходимо заполнить все поля корректно' });
    try {
      const validation = await Promise.allSettled(Object.values(forms).map((f) => f.validateFields()));

      const descriptionTextLengthError = getTextEditorValidationError(
        forms,
        SERVICE_DESCRIPTION_MAX_LENGTH,
        'description',
        'base-info',
      );

      const suitableForTextLengthError = getTextEditorValidationError(
        forms,
        SERVICE_SUITABLE_FOR_MAX_LENGTH,
        'suitableFor',
        'base-info',
      );
      const functionalTextLengthError = getTextEditorValidationError(
        forms,
        SERVICE_SUITABLE_FOR_MAX_LENGTH,
        'functional',
        'base-info',
      );

      const hasInvalidValues =
        validation.find((v) => v.status === 'rejected') ||
        descriptionTextLengthError ||
        suitableForTextLengthError ||
        functionalTextLengthError;

      // Если валидация основных полей прошла - дальше валидируем поля с приложениями и потребностями
      if (!hasInvalidValues) {
        let data = {
          apps: [] as Array<ServiceApp>,
          needs: needsProps.needs,
        };

        Object.entries(forms).forEach(([name, form]) => {
          const isAppForm = name.includes('application-info');
          if (isAppForm) {
            const appName = name.split('-').pop();
            const appFormData = form.getFieldsValue();
            const hasValue = appFormData.link && appFormData.version;
            if (appName && hasValue) {
              data.apps.push({ ...form.getFieldsValue(), name: appName });
            }
          } else {
            data = { ...data, ...form.getFieldsValue() };
          }
        });

        if (data.apps.length === 0) {
          showFormErrorMessages(validation, 'Должно быть заполнено хотя бы одно приложение');
          return;
        }

        if (data.apps.length > 0 && data.apps?.every((item) => !item.isBeta))
          showFormErrorMessages(
            validation,
            'Для секции приложений обязательно должно быть выбрано хотя бы одно основное приложение',
          );
        else onSave(data as FrontendService);
      } else {
        const getAdditionalErrorMessage = () => {
          if (descriptionTextLengthError)
            return `Описание должно быть менее ${SERVICE_DESCRIPTION_MAX_LENGTH} символов`;
          if (suitableForTextLengthError)
            return `Поле "Подходит для" должно быть менее ${SERVICE_SUITABLE_FOR_MAX_LENGTH} символов`;
          if (functionalTextLengthError)
            return `Поле "Функционал сервиса" должно быть менее ${SERVICE_SUITABLE_FOR_MAX_LENGTH} символов`;
          return undefined;
        };
        showFormErrorMessages(validation, getAdditionalErrorMessage());
      }
    } catch (error) {
      errorMessage();
    }
  };

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Layout>
        <EntityHeader
          title={service?.title || ''}
          onArchiveButtonClick={service.id === undefined || isNaN(service.id) ? undefined : onDeleteButtonClick}
        />
        <TabsWithButtons
          filteredTabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onLeftButtonClick={goToPrevTab}
          onRightButtonClick={goToNextTab}
        />
        <Content className="mt-40 p-16 rounded-16 bg-neutral">
          {tabs.map(({ key, label, formHeaderType, contentClassName }) => {
            return (
              <div className={contentClassName} key={key}>
                <FormHeader title={label} type={formHeaderType} />
                <div>{tabContent[key]}</div>
              </div>
            );
          })}
        </Content>
      </Layout>
    </Form.Provider>
  );
};
