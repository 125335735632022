import { Pagination, Typography } from 'antd';
import { axios } from 'client';
import { Index } from 'client/endpoints';
import { FormHeader } from 'components/FormHeader';
import { ListCard } from 'components/ListCard';
import { AdminGetChildrenEventsForEventResponse } from 'models/models/AdminGetChildrenEventsForEventResponse';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

type EventLinkedParentProps = {
  placeId: string;
  isActive?: boolean;
};

/**
 *
 * @param isActiveForm - Определяет, является ли текущая форма активной (открыт ли таб, в котором она находится)
 *
 */

export const EventRelatedPlacesAndEventsGlobal = ({ placeId, isActive }: EventLinkedParentProps) => {
  const navigate = useNavigate();

  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: 4,
  });

  const fetch = async () => {
    const { data } = await axios.get(
      `${Index.adminEvents}/${placeId}/children-events?
        ?&offset=${paginationData.current === 1 ? '0' : (paginationData.current - 1) * paginationData.pageSize}&limit=${
        paginationData.pageSize
      }`,
    );
    return data;
  };

  const { data } = useQuery<BaseResponse<AdminGetChildrenEventsForEventResponse[], PageMeta>>(
    ['childrenEvents', paginationData],
    fetch,
    { enabled: isActive },
  );

  const toEvent = (id: number) => {
    navigate(`/events/${id}`);
  };

  return (
    <div className="mt-16">
      <FormHeader
        title={
          <Typography.Title className="m-0 d-flex flex-items-end" level={3}>
            Разовые события
          </Typography.Title>
        }
        type="fullwidth"
      />
      <div className="d-flex gap-8 flex-wrap">
        {data?.data.length === 0 ? (
          <div className="d-flex flex-justify-center ">Отсутствуют связанные места</div>
        ) : null}
        {data?.data.map(({ id, image, title }) => (
          <ListCard key={id} title={title} imageUrl={image ?? ''} onClick={() => toEvent(id)} />
        ))}
      </div>
      {data?.meta.total ? (
        <div className="d-flex flex-justify-end">
          <Pagination
            {...paginationData}
            onChange={(page, pageSize) => {
              setPaginationData({ current: page, pageSize });
            }}
            total={data.meta.total}
            defaultCurrent={paginationData.current}
            pageSizeOptions={[4, 8, 12, 16, 20]}
          />
        </div>
      ) : null}
    </div>
  );
};
