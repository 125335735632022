import React from 'react';
import { Form } from 'antd';
import { FormList } from 'components/FormList';
import { StyledInput } from 'components/StyledInputs/StyledInput';
import { SocialForm, TSocialRole } from 'forms/SocialForm/SocialForm';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export type ContactValues = {
  sites: string[];
  phones: string[];
  emails: string[];
  legalAddress: string;
  socials: TSocialRole[];
};
export type ContactsFormProps = {
  initialValues?: Optional<ContactValues>;
  withLegalAddress?: boolean;
  isSiteRequired?: boolean;
  isLegalAddressRequired?: boolean;
};

export const ContactsForm: React.FC<ContactsFormProps> = ({
  withLegalAddress = true,
  initialValues: { socials, ...rest } = {},
  isSiteRequired = true,
  isLegalAddressRequired = true,
}) => {
  const [form] = Form.useForm();

  return (
    <>
      <Form form={form} initialValues={rest} {...layout} name="contacts-form">
        {withLegalAddress && (
          <Form.Item
            name="legalAddress"
            label="Юридический адрес"
            rules={[{ required: withLegalAddress && isLegalAddressRequired, min: 1 }]}
          >
            <StyledInput />
          </Form.Item>
        )}
        <FormList
          label="Телефон"
          name="phones"
          rules={[{ required: false, type: 'string', message: 'Введите корректный телефон' }]}
          initialFirstElement
        />
        <FormList
          label="E-mail"
          name="emails"
          initialFirstElement
          rules={[{ required: false, type: 'email', message: 'Пожалуйста, введите корректный e-mail адрес' }]}
        />
        <FormList
          label="Сайт"
          name="sites"
          required={isSiteRequired}
          initialFirstElement
          rules={[{ required: isSiteRequired, type: 'url', message: 'Пожалуйста, введите корректную ссылку на сайт' }]}
        />
      </Form>
      <SocialForm socials={socials} />
    </>
  );
};
