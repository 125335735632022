import { axios } from 'client';
import { Index } from 'client/endpoints';
import { UserContext } from 'contexts/UserContext';
import { AdminNewsResponseDto } from 'models';
import { useContext } from 'react';
import { useQuery } from 'react-query';

/**
 * @param enabled Флаг, который указывает, нужно ли выполнять запрос на получение новостей
 */

export const useNews = (enabled = true) => {
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';

  const fetchData = async () => {
    const { data } = await axios.get(Index.news);
    return data.data;
  };

  return useQuery<AdminNewsResponseDto[]>(['getNews', isAuthorized], fetchData, {
    enabled: isAuthorized && enabled,
    initialData: [],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
