import { Form, SelectProps } from 'antd';
import { BaseOptionType } from 'antd/lib/select';
import { Index } from 'client/endpoints';
import { DebouncedSelect } from 'components/DebouncedSelect';
import { DebouncedSelectProps, SelectOptions } from 'components/DebouncedSelect/DebouncedSelect';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { Place } from 'models';
import React from 'react';
import { StyledInput } from '../StyledInputs/StyledInput';
import { PlaceFrontend } from 'client/mappers/place';
import { useParentPlaces } from 'client/hooks/places';

export const AsyncPlaceSelect: React.FC<
  SelectProps &
    Pick<DebouncedSelectProps, 'initialOptions'> & {
      formitemname?: string;
      required?: boolean;
      currentPlaceId?: number;
    }
> = (props) => {
  const mapDadataResponse = (places?: Place[]): SelectOptions => {
    return places?.map((p) => ({ key: p.id, value: p.id, label: p.title, address: p.address }));
  };
  const defaultSelectName = props.formitemname === undefined ? 'parent' : props.formitemname;
  const { fetcher } = useAsyncSelectOptions<Place>(`${Index.parentPlaces}?query=`, mapDadataResponse);

  const { data: places } = useParentPlaces();

  const currentPlaceId = props.currentPlaceId;

  return (
    <Form.Item label="Название места" name={[defaultSelectName, 'id']} rules={[{ required: props.required ?? true }]}>
      <DebouncedSelect
        fetcher={fetcher}
        {...props}
        initialOptions={mapDadataResponse(places)}
        currentPlaceId={currentPlaceId}
      />
    </Form.Item>
  );
};

type AsyncParentPlaceSelectProps = {
  initialParent: PlaceFrontend['parent'];
  formItemName: string;
  currentPlaceId?: number;
};

export const AsyncParentPlaceSelect: React.FC<AsyncParentPlaceSelectProps> = (props) => {
  const form = Form.useFormInstance();
  const currentPlaceId = props.currentPlaceId;

  const initialId = form?.getFieldValue([props.formItemName, 'id']) || props.initialParent?.id;
  const initialLabel = form?.getFieldValue([props.formItemName, 'name']) || props.initialParent?.name;

  const initialOptions: Optional<SelectOptions> =
    initialId && initialLabel ? [{ key: initialId, value: initialId, label: initialLabel }] : undefined;

  const onChange = (_: unknown, option: BaseOptionType) => {
    form.setFields([
      { name: [props.formItemName, 'address'], value: option.address },
      { name: [props.formItemName, 'name'], value: option.label },
    ]);
  };

  return (
    <>
      <AsyncPlaceSelect
        onChange={onChange}
        initialOptions={initialOptions}
        formitemname={props.formItemName}
        currentPlaceId={currentPlaceId}
        placeholder="Поиск от трех символов"
      />
      {/* Нужно для инициализации в селекте initial value */}
      <Form.Item className="d-none" name={[props.formItemName, 'name']} label="Название">
        <StyledInput readOnly />
      </Form.Item>
      <Form.Item className="d-none" name={[props.formItemName, 'address']} label="Адрес">
        <StyledInput readOnly />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, style: { minHeight: 0 } }} className="m-0" shouldUpdate>
        {({ getFieldValue }) => {
          const address = getFieldValue([props.formItemName, 'address']);
          return address && <div className="-mt-24 mb-16">{address}</div>;
        }}
      </Form.Item>
      <Form.Item shouldUpdate className="m-0" label="Пояснение">
        {({ getFieldValue }) => {
          const parentName = getFieldValue([props.formItemName, 'name']);
          return (
            parentName && (
              <Form.Item name="clarification">
                <StyledInput />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
    </>
  );
};
