import React from 'react';
import { DebouncedSelectProps, SelectOptions } from 'components/DebouncedSelect/DebouncedSelect';
import { Index } from 'client/endpoints';
import { EventTypesResponse, TypeCategoryDto } from 'models';
import { Form, SelectProps } from 'antd';
import useAxios from 'axios-hooks';
import { StyledSelectComponent } from 'components/StyledSelect';

export const EventTypeSelect: React.FC<
  SelectProps & Pick<DebouncedSelectProps, 'initialOptions'> & { required?: boolean }
> = (props) => {
  const mapDadataResponse = (eventTypes: EventTypesResponse['data']): SelectOptions => {
    return eventTypes.reduce<SelectOptions>((acc, p) => {
      p.items.forEach((i) => {
        acc?.push({ key: i, value: i, label: i });
      });
      return acc;
    }, []);
  };

  const [{ data: eventData, loading: isEventLoading }] = useAxios<BaseResponse<TypeCategoryDto[]>>(
    {
      url: `${Index.eventsTypes}`,
      method: 'GET',
    },
    { manual: false },
  );

  const mappedEventTypes = eventData !== undefined && !isEventLoading ? mapDadataResponse(eventData.data) : [];

  return (
    <Form.Item name={'eventType'} label="Тип события" rules={[{ required: props.required ?? true }]}>
      <StyledSelectComponent options={mappedEventTypes} {...props} />
    </Form.Item>
  );
};
