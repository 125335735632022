import { Form } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { rolesMapper } from 'client/mappers/author';
import { DebouncedSelect } from 'components/DebouncedSelect';
import { FormList } from 'components/FormList';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { RolesResponseDto } from 'models';
import { FC } from 'react';
import { BASE_ROLES_SEARCH_URL } from 'utils';
import { LabelKeyValueType } from 'utils/types';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export type LinkedRolesFormProps = {
  initialValues: {
    roles?: Array<LabelKeyValueType>;
  };
  options?: DefaultOptionType[];
};
export const AuthorRolesForm: FC<LinkedRolesFormProps> = ({ initialValues, options }) => {
  const [form] = Form.useForm<LinkedRolesFormProps['initialValues']>();

  const { fetcher } = useAsyncSelectOptions<RolesResponseDto>(BASE_ROLES_SEARCH_URL, rolesMapper);
  const defaultValue = { value: '', label: '' };

  return (
    <Form name="roles" {...layout} form={form} initialValues={initialValues}>
      <FormList
        label="Выбранные"
        name="roles"
        defaultValue={defaultValue}
        customController={<DebouncedSelect fetcher={fetcher} initialOptions={options} labelInValue={true} />}
      />
    </Form>
  );
};
