import { Form, Layout, notification } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FormProviderProps } from 'antd/lib/form/context';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { EntityHeader } from '../../components/EntityHeader';
import { FormHeader } from '../../components/FormHeader';
import { TabsWithButtons } from '../../components/TabsWithButtons/TabsWithButtons';
import { TAB_PREFIX, useTabs, videosTabKeys, videosTabs } from '../../hooks/useTabs';
import { showFormErrorMessages } from '../../utils/showFormErrorMessages';
import { VideoConnectionsForm } from './forms/VideoItemConnectionsForm';
import { VideoItemBaseInfoForm } from './forms/VideoItemBaseInfoForm';
import { VideoData, VideoItemFormValues } from './types';

type Props = {
  videoData: VideoData;
  onSave: (newDataForSave: VideoItemFormValues, imageFileParams?: string | Blob | RcFile) => void;
};

export const VideoContent: React.FC<Props> = ({ videoData, onSave }) => {
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get(TAB_PREFIX)?.toString();
  const { activeTab, tabs, setActiveTab, goToNextTab, goToPrevTab } = useTabs(videosTabs, initialTab);

  const videoInitialValues = { ...videoData };

  const tabContent = {
    [videosTabKeys.common]: <VideoItemBaseInfoForm initialValues={videoInitialValues} />,
    [videosTabKeys.connections]: (
      <VideoConnectionsForm isActiveForm={activeTab === videosTabKeys.connections} initialValues={videoInitialValues} />
    ),
  };

  const onFormFinish: FormProviderProps['onFormFinish'] = async (_, { forms }) => {
    const errorMessage = () => notification.error({ message: 'Необходимо заполнить все поля корректно' });
    try {
      const validation = await Promise.allSettled(Object.values(forms).map((f) => f.validateFields()));

      const hasInvalidValues = validation.find((v) => v.status === 'rejected');

      if (!hasInvalidValues) {
        const data = {};
        Object.entries(forms).forEach(([_, form]) => {
          Object.assign(data, form.getFieldsValue());
        });

        onSave(data as VideoItemFormValues);
      } else {
        showFormErrorMessages(validation);
      }
    } catch (error) {
      errorMessage();
    }
  };

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Layout>
        <EntityHeader
          title={videoData.title || 'Создать Новость'}
          // onArchiveButtonClick={!isCreationMode ? onDeleteButtonClick : undefined}
        />
        <TabsWithButtons
          filteredTabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onLeftButtonClick={goToPrevTab}
          onRightButtonClick={goToNextTab}
        />
        <Content className="mt-40 p-16 rounded-16 bg-neutral">
          {tabs.map(({ key, label, contentClassName, formHeaderType }) => {
            return (
              <div key={key} className={contentClassName}>
                <FormHeader title={label} type={formHeaderType} />
                <div>{tabContent[key]}</div>
              </div>
            );
          })}
        </Content>
      </Layout>
    </Form.Provider>
  );
};
