import { Layout, Spin, Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '../../components/PageTitle';
import { StyledInput } from '../../components/StyledInputs/StyledInput';
import { NAVIGATION } from '../../paths';
import { getSlicedArray } from '../../utils';
import { getArrayLengthRestInformation } from '../../utils/arrayHandlers';
import { useSocialNeeds } from 'client/hooks/needs';

interface NeedsDataType {
  key: React.Key;
  title: string;
  linkedRoles: Array<string>;
}
const columns: ColumnsType<NeedsDataType> = [
  {
    title: 'Название потребности',
    dataIndex: 'title',
    sortDirections: ['descend', 'ascend'],
    render: (title: string) => {
      return (
        <p title={title} className={'m-0 text-overflow w-50 overflow-hidden'}>
          {title}
        </p>
      );
    },
  },
  {
    title: 'Связанные роли',
    dataIndex: 'linkedRoles',
    render: (linkedRoles: string[]) => {
      const slicedArray = getSlicedArray(linkedRoles);
      return (
        <div className={'d-flex gap-4'}>
          {slicedArray.map((item, idx) => {
            return (
              <p className={'m-0 text-overflow w-150px tag'} key={idx} title={item}>
                {item}
              </p>
            );
          })}
          {<span>{getArrayLengthRestInformation(linkedRoles)}</span>}
        </div>
      );
    },
  },
];
export const Needs = () => {
  const push = useNavigate();
  const { needs, updatePaginationInfo, totalCount, updateSearchQuery, isLoading } = useSocialNeeds();

  const [searchValue, setSearchValue] = useState('');

  // TODO: Роли не приходят
  const tableData: Array<NeedsDataType> = needs.map((item) => {
    const linkedRoles =
      item.roles.length >= 1
        ? item.roles.map((item) => {
            return item.name;
          })
        : ['Отсутствуют'];
    return {
      key: item.id,
      title: item.name,
      linkedRoles: linkedRoles,
    };
  });
  const onChange: TableProps<NeedsDataType>['onChange'] = ({ current, pageSize = 10 }) => {
    if (current !== undefined && pageSize !== undefined) {
      const offset = current === 1 ? '0' : (current - 1) * pageSize;
      updatePaginationInfo({ offset: +offset, limit: pageSize });
    }
  };

  const handleSearchInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = e.target.value;
      updateSearchQuery(searchValue);
      setSearchValue(searchValue);
    },
    [updateSearchQuery],
  );

  return (
    <Layout>
      <PageTitle
        title="Потребности"
        wrapperClassName="mb-24"
        titleClassName={'mb-24'}
        onAddButtonClick={() => push(`${NAVIGATION.needs}/new`)}
      />

      <Content>
        <div className="p-24 mt-20 bg-neutral mb-24 rounded-8">
          <StyledInput value={searchValue} placeholder="Название потребности" onChange={handleSearchInputChange} />
        </div>
        <Table
          pagination={{
            total: totalCount,
            pageSizeOptions: ['10', '20'],
            defaultPageSize: 10,
          }}
          rowClassName={'cursor-pointer'}
          columns={columns}
          dataSource={tableData}
          onChange={onChange}
          onRow={(record) => {
            return {
              onClick: () => {
                push(`${NAVIGATION.needs}/${record.key}`);
              },
            };
          }}
          loading={
            isLoading && {
              indicator: (
                <div>
                  <Spin />
                </div>
              ),
            }
          }
        />
      </Content>
    </Layout>
  );
};
