import { axios } from 'client';
import { Index } from 'client/endpoints';
import { UserContext } from 'contexts/UserContext';
import { Place } from 'models';
import { useContext } from 'react';
import { useQuery } from 'react-query';

export const useParentPlaces = () => {
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';
  const fetchData = async () => {
    if (!isAuthorized) return;
    const { data } = await axios.get(`${Index.parentPlaces}?query=`);
    return data.data;
  };

  return useQuery<Place[]>(['getParentPlaces', isAuthorized], fetchData, {
    initialData: [],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
