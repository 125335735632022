import React, { FC, useState } from 'react';
import { DebouncedSelect } from 'components/DebouncedSelect';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { DADATA_BASE_SEARCH_URL } from 'utils';
import { Form } from 'antd';
import { AddressSuggestionResponse } from 'models';
import { CitySelect } from '../CitySelect';
import { DefaultOptionType } from 'antd/lib/select';

type Props = {
  enableCitySelect?: boolean;
};

export const DadataSelect: FC<Props> = ({ enableCitySelect = true }) => {
  const form = Form.useFormInstance();
  const mapDadataResponse = (data: AddressSuggestionResponse[]) => {
    return data.map(({ value }) => {
      return { label: value, value, key: value };
    });
  };

  const [selectedCity, setSelectedCity] = useState(form.getFieldValue('area'));
  const [resetOptions, setResetOptions] = useState(false);

  const onCitySelect = (option: DefaultOptionType) => {
    form.setFieldValue('address', '');
    setSelectedCity(option);
    setResetOptions(true);
    setTimeout(() => setResetOptions(false), 500);
  };

  const { fetcher } = useAsyncSelectOptions<AddressSuggestionResponse>(
    `${DADATA_BASE_SEARCH_URL}${selectedCity?.label ?? ''} `,
    mapDadataResponse,
  );

  return (
    <>
      {enableCitySelect && <CitySelect onCitySelect={onCitySelect} isMultiple={false} />}
      <Form.Item rules={[{ min: 5, required: true }]} name="address" label="Адрес">
        <DebouncedSelect fetcher={fetcher} disabled={!selectedCity && enableCitySelect} resetOptions={resetOptions} />
      </Form.Item>
    </>
  );
};
