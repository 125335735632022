import { useNavigate } from 'react-router-dom';
import { eventTabKeys, TAB_PREFIX } from 'hooks/useTabs';
import { Content } from 'antd/lib/layout/layout';
import styles from './styles.module.css';
import { Typography } from 'antd';
import { ListCard, ListCardProps } from 'components/ListCard';

type ListCardWithId = ListCardProps & { id: number };
type EventLinkedParentProps = {
  parentCard?: ListCardWithId;
  place?: ListCardWithId;
  children?: Array<ListCardWithId>;
  address?: string;
};

export const EventRelatedPlacesAndEvents = ({ parentCard, place, address, children }: EventLinkedParentProps) => {
  const navigate = useNavigate();

  const toPlacePage = () => {
    navigate(`/places/${place?.id}`);
  };
  const toEvent = (id: string) => {
    navigate(`/events/${id}?${TAB_PREFIX}=${eventTabKeys.relatedPlacesAndEvents}`);
  };

  return (
    <div className="d-flex flex-column gap-40">
      <div className={`d-flex flex-justify-between ${styles.wrapper}`}>
        {place !== undefined || (address && address.length > 1) ? (
          <Content className="mt-24 p-16 rounded-16 bg-neutral">
            <div className="flex-justify-center flex-column">
              <div className={styles.cardHeader}>
                <h2>{place ? 'Место' : 'Адрес'} проведения</h2>
              </div>
              <div className="flex-justify-center d-flex">
                {place && <ListCard {...place} onClick={toPlacePage} />}
                {address && !place && <span>{address}</span>}
              </div>
            </div>
          </Content>
        ) : (
          <Content className="mt-24 p-16 pl-48 rounded-16 bg-neutral">
            <Typography.Text>Отсутствуют связанные места или события</Typography.Text>
          </Content>
        )}

        {parentCard && (
          <Content className="mt-24 p-16 rounded-16 bg-neutral">
            <div className="flex-justify-center flex-column">
              <div className={styles.cardHeader}>
                <h2>Глобальное мероприятие</h2>
              </div>
              <div className="flex-justify-center d-flex">
                {parentCard && (
                  <ListCard
                    {...parentCard}
                    onClick={() => {
                      toEvent(parentCard?.id.toString());
                    }}
                  />
                )}
              </div>
            </div>
          </Content>
        )}
      </div>
      {children !== undefined && children.length >= 1 && (
        <div className={'d-flex flex-column mt-48'}>
          <h2>Список локальных мероприятий</h2>
          <div className="d-flex gap-16 flex-wrap">
            {children.map((item) => {
              return (
                <ListCard
                  {...item}
                  key={item.id}
                  onClick={() => {
                    toEvent(item.id.toString());
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
