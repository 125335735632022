import React, { useEffect, useState } from 'react';
import { Upload, UploadFile } from 'antd';
import { FormHeader } from 'components/FormHeader';
import { PlusOutlined } from '@ant-design/icons';
import { useEntityUploadImage } from 'hooks';
import { FrontendService } from 'client/mappers/service';
import { GalleryItem, ImageEntity, ImageType } from 'hooks/useUploadImage';
import { GalleryImage } from 'components/GalleryImage';
import { useServiceDeleteImage } from 'client/hooks/services';
import { MAX_AMOUNT_OF_IMAGES } from 'utils/constants';

type ServiceReviewScreensProps = Pick<FrontendService, 'screens'>;

export const ServiceReviewScreens: React.FC<ServiceReviewScreensProps> = ({ screens }) => {
  const [fileList, setFileList] = useState(screens);
  const { uploadImage: customRequest, isLoading } = useEntityUploadImage(
    ImageType.Screenshot,
    ImageEntity.Services,
    (newFiles) => setFileList((pV) => [...pV, ...newFiles]),
  );

  const deleteImage = useServiceDeleteImage(ImageEntity.Services, ImageType.Screenshot, (imageId) => {
    setFileList((pV) => pV.filter(({ uid }) => uid !== imageId));
  });

  const handleDeleteImage = ({ uid }: UploadFile) => {
    deleteImage(uid);
  };

  useEffect(() => {
    if (isLoading) setFileList((prev) => [...prev, { url: '', name: 'Загрузка...', uid: 'loading' }]);
    else {
      setFileList((prev) => prev.filter((image) => image.uid !== 'loading'));
    }
  }, [isLoading]);

  return (
    <div>
      <FormHeader title="Обзор" type="fullwidth" level={4} />
      <Upload
        accept=".jpeg,.jpg,.png"
        className="service-gallery-upload"
        itemRender={(_, { url, name, thumbUrl }: GalleryItem & Pick<UploadFile, 'thumbUrl'>, __, { remove }) => (
          <GalleryImage initialFavoriteImage={undefined} remove={remove} name={name} url={url} thumbUrl={thumbUrl} />
        )}
        showUploadList={{ previewIcon: undefined, removeIcon: false }}
        onRemove={handleDeleteImage}
        customRequest={customRequest}
        listType="picture-card"
        fileList={fileList}
      >
        {fileList.length < MAX_AMOUNT_OF_IMAGES && <PlusOutlined />}
      </Upload>
    </div>
  );
};
