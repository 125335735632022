import { Form } from 'antd';
import { DebouncedSelect } from 'components/DebouncedSelect';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { AdminCitiesAreasResponseDto } from 'models';
import React, { FC } from 'react';
import { getCitiesEndpointWithSearchUrl } from '../../client/endpoints/cities';
import { useCitiesEntity } from 'client/hooks/cities';
import { DefaultOptionType } from 'antd/lib/select';

type Props = {
  onCitySelect?: (option: DefaultOptionType) => void;
  isMultiple?: boolean;
};

export const CitySelect: FC<Props> = ({ onCitySelect, isMultiple = true }) => {
  const mapDadataResponse = (data?: AdminCitiesAreasResponseDto[]) => {
    return data?.map(({ name, id }) => {
      return { label: name, key: id, value: name };
    });
  };

  const { data: cities } = useCitiesEntity();

  const mappedCities = mapDadataResponse(cities);

  const { fetcher } = useAsyncSelectOptions(getCitiesEndpointWithSearchUrl(), mapDadataResponse);

  return (
    <>
      <Form.Item rules={[{ required: true }]} name="area" label="Город">
        <DebouncedSelect
          fetcher={fetcher}
          onSelect={(_, option) => {
            onCitySelect && onCitySelect(option);
          }}
          labelInValue={true}
          initialOptions={mappedCities}
          mode={isMultiple ? 'multiple' : undefined}
        />
      </Form.Item>
    </>
  );
};
