import { Index } from './index';

export const getVideosEndpointUrl = (searchValue: string, checkedFilter: string | null) => {
  const query = new URLSearchParams();
  if (checkedFilter) query.append('platform', checkedFilter);
  if (searchValue) query.append('search', searchValue);

  return `${Index.videosAdmin}?${query.toString()}`;
};

export const getVideoByIdEndpointUrl = (id?: string) => ({ url: `${Index.videosAdmin}/${id}`, method: 'GET' });
export const updateVideoBaseInfoEndpointUrl = (id?: string) => ({ url: `${Index.videosAdmin}/${id}`, method: 'PATCH' });

export const updateVideoRolesEndpointUrl = (id?: string) => ({
  url: `${Index.videosAdmin}/${id}/roles`,
  method: 'PATCH',
});

export const updateVideoAreasEndpointUrl = (id?: string) => ({
  url: `${Index.videosAdmin}/${id}/areas`,
  method: 'PATCH',
});
