import React from 'react';
import { DebouncedSelectProps, SelectOptions } from 'components/DebouncedSelect/DebouncedSelect';
import { Index } from 'client/endpoints';
import { TypeCategoryDto } from 'models';
import { SelectProps } from 'antd';
import useAxios from 'axios-hooks';
import { StyledSelectComponent } from 'components/StyledSelect';

type PlaceTypeSelectProps = SelectProps & Pick<DebouncedSelectProps, 'initialOptions'>;

export const PlaceTypeSelect: React.FC<PlaceTypeSelectProps> = ({ ...selectProps }) => {
  const mapDadataResponse = (eventTypes: TypeCategoryDto[]): SelectOptions => {
    return eventTypes.reduce<SelectOptions>((acc, p) => {
      p.items.forEach((i) => {
        acc?.push({ value: i, label: i });
      });
      return acc;
    }, []);
  };

  const [{ data: eventData, loading: isEventLoading }] = useAxios<BaseResponse<TypeCategoryDto[]>>({
    url: `${Index.placeTypes}`,
    method: 'GET',
  });

  const mappedEventTypes = eventData !== undefined && !isEventLoading ? mapDadataResponse(eventData.data) : [];

  return <StyledSelectComponent options={mappedEventTypes} {...selectProps} />;
};
