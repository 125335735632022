import React, { useState } from 'react';
import { Pagination, Typography } from 'antd';
import { FormHeader } from 'components/FormHeader';
import { Index } from 'client/endpoints';
import { ListCard } from 'components/ListCard';
import { NAVIGATION } from 'paths';
import { useNavigate } from 'react-router-dom';
import { Place } from 'models';
import { placeTabKeys, TAB_PREFIX } from 'hooks/useTabs';
import { axios } from 'client';
import { useQuery } from 'react-query';

/**
 *
 * @param isActiveForm - Определяет, является ли текущая форма активной (открыт ли таб, в котором она находится)
 */

export const RelatedPlaces: React.FC<{ id: number; isActive?: boolean }> = ({ id, isActive = true }) => {
  const push = useNavigate();
  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: 4,
  });

  const fetch = async () => {
    const { data } = await axios.get(
      `${Index.places}/${id}/linked?&offset=${
        paginationData.current === 1 ? '0' : (paginationData.current - 1) * paginationData.pageSize
      }&limit=${paginationData.pageSize}`,
    );
    return data;
  };

  const { data } = useQuery<BaseResponse<Place[], PageMeta>>(['relatedPlaces', paginationData], fetch, {
    enabled: isActive,
  });

  return (
    <div>
      <FormHeader
        title={
          <Typography.Title className="m-0 d-flex flex-items-end" level={3}>
            Размещеннные места
          </Typography.Title>
        }
        type="fullwidth"
      />
      <div className="d-flex gap-8 flex-wrap">
        {data?.data.length === 0 ? (
          <div className="d-flex flex-justify-center ">Отсутствуют связанные места</div>
        ) : null}
        {data?.data.map(({ id, image, type, title }) => (
          <ListCard
            key={id}
            title={title}
            imageUrl={image}
            footerDescription={type}
            onClick={() => push(`${NAVIGATION.places}/${id}?${TAB_PREFIX}=${placeTabKeys.connections}`)}
          />
        ))}
      </div>
      {data?.meta.total ? (
        <div className="d-flex flex-justify-end">
          <Pagination
            {...paginationData}
            onChange={(page, pageSize) => {
              setPaginationData({ current: page, pageSize });
            }}
            total={data.meta.total}
            defaultCurrent={paginationData.current}
            pageSizeOptions={[4, 8, 12, 16, 20]}
          />
        </div>
      ) : null}
    </div>
  );
};
