export enum PUSH_ENTITIES_KEYS {
  news = 'news',
  services = 'services',
  events = 'events',
  places = 'places',
  authors = 'authors',
}

export const PUSH_ENTITIES: Record<PUSH_ENTITIES_KEYS, string> = {
  [PUSH_ENTITIES_KEYS.news]: 'Новости',
  [PUSH_ENTITIES_KEYS.services]: 'Сервисы',
  [PUSH_ENTITIES_KEYS.events]: 'События',
  [PUSH_ENTITIES_KEYS.places]: 'Места',
  [PUSH_ENTITIES_KEYS.authors]: 'Авторы и персоны',
};

export const mappedPushEntities = (Object.keys(PUSH_ENTITIES) as (keyof typeof PUSH_ENTITIES)[]).map((item) => ({
  value: item,
  label: PUSH_ENTITIES[item],
}));
