import { Form, Layout, notification } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FormProviderProps } from 'antd/lib/form/context';
import { Content } from 'antd/lib/layout/layout';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getTextEditorValidationError } from 'utils/getTextEditorValidationError';
import { EntityHeader } from '../../components/EntityHeader';
import { FormHeader } from '../../components/FormHeader';
import { TabsWithButtons } from '../../components/TabsWithButtons/TabsWithButtons';
import { NEW_BODY_MAX_LENGTH } from '../../constants';
import { newsTabKeys, newsTabs, TAB_PREFIX, useTabs } from '../../hooks/useTabs';
import { showFormErrorMessages } from '../../utils/showFormErrorMessages';
import { NewItemImage } from './NewItemImage';
import { FrontendNewItem } from './types';
import { NewItemBaseInfoForm } from './forms/NewItemBaseInfoForm';
import { NewItemRolesForm } from './forms/NewItemRolesForm';
import { useSocialRolesList } from 'client/hooks/socialRoles';
import { rolesMapper } from 'client/mappers/author';

type Props = {
  newData: FrontendNewItem;
  onDeleteButtonClick: () => void;
  onSave: (newDataForSave: FrontendNewItem, imageFileParams?: string | Blob | RcFile) => void;
  isCreationMode?: boolean;
};

export const NewContent: React.FC<Props> = ({ newData, onSave, onDeleteButtonClick, isCreationMode }) => {
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get(TAB_PREFIX)?.toString();
  const { activeTab, tabs, setActiveTab, goToNextTab, goToPrevTab } = useTabs(newsTabs, initialTab);
  const [imageFile, setImageFile] = useState<string | Blob | RcFile | null>(null);

  const newInitialValues = { ...newData };

  const { roles } = useSocialRolesList(activeTab === newsTabKeys.roles);

  const mappedRoles = rolesMapper(roles);

  const tabContent = {
    [newsTabKeys.common]: <NewItemBaseInfoForm initialValues={newInitialValues} />,
    [newsTabKeys.gallery]: (
      <NewItemImage
        image={newData.image}
        newId={newData.id}
        isCreationMode={isCreationMode}
        setImageFile={setImageFile}
      />
    ),
    [newsTabKeys.roles]: <NewItemRolesForm roles={mappedRoles} initialValues={{ socialRoles: newData.socialRoles }} />,
  };

  const onFormFinish: FormProviderProps['onFormFinish'] = async (_, { forms }) => {
    const errorMessage = () => notification.error({ message: 'Необходимо заполнить все поля корректно' });
    try {
      const validation = await Promise.allSettled(Object.values(forms).map((f) => f.validateFields()));
      const descriptionTextLengthError = getTextEditorValidationError(forms, NEW_BODY_MAX_LENGTH, 'body');

      const hasInvalidValues = validation.find((v) => v.status === 'rejected') || descriptionTextLengthError;

      if (!hasInvalidValues) {
        const data = {};
        Object.entries(forms).forEach(([_, form]) => {
          Object.assign(data, form.getFieldsValue());
        });
        onSave(data as FrontendNewItem, imageFile as string | Blob | RcFile);
      } else {
        showFormErrorMessages(
          validation,
          descriptionTextLengthError ? `Тело новости должно быть менее ${NEW_BODY_MAX_LENGTH} символов` : undefined,
        );
      }
    } catch (error) {
      errorMessage();
    }
  };

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Layout>
        <EntityHeader
          title={newData.title || 'Создать Новость'}
          onArchiveButtonClick={!isCreationMode ? onDeleteButtonClick : undefined}
        />
        <TabsWithButtons
          filteredTabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onLeftButtonClick={goToPrevTab}
          onRightButtonClick={goToNextTab}
        />
        <Content className="mt-40 p-16 rounded-16 bg-neutral">
          {tabs.map(({ key, label, contentClassName, formHeaderType }) => {
            return (
              <div key={key} className={contentClassName}>
                <FormHeader title={label} type={formHeaderType} />
                <div>{tabContent[key]}</div>
              </div>
            );
          })}
        </Content>
      </Layout>
    </Form.Provider>
  );
};
