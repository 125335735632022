import { ClientRole } from 'client/hooks/socialRoles';
import {
  AdminAuthorResponseDto,
  AdminAuthorRolesListResponseDto,
  AdminCitiesAreasResponseDto,
  AuthorVideoResponse,
  Role,
  RolesListResponse,
} from 'models';
import { FrontendAuthorItem } from 'pages/Author/types';

export const getNewAuthorDefaultData = (): FrontendAuthorItem => ({
  id: NaN,
  news: [],
  events: [],
  videos: [],
  services: [],
  recommendedEvents: [],
  places: [],
  name: '',
  description: '',
  announcement: '',
  image: '',
  roles: [],
  showInTagCloud: false,
  showInRecommendation: false,
  noShowInMain: false,
  createdAt: '',
  isTopAuthor: false,
  priority: 30,
});

export const mapAuthorForFrontend = (data: AdminAuthorResponseDto): FrontendAuthorItem => {
  const {
    id,
    name,
    announcement,
    description,
    image,
    isTopAuthor,
    showInTagCloud,
    showInRecommendation,
    noShowInMain,
    createdAt,
    priority,
  } = data;
  return {
    id,
    name,
    announcement,
    description,
    image,
    priority,
    isTopAuthor,
    showInTagCloud,
    showInRecommendation,
    noShowInMain,
    createdAt,
    roles: rolesMapper(data.roles),
    news: data.news?.length ? baseBindingMapper(data.news) : undefined,
    events: data.events?.length ? baseBindingMapper(data.events) : undefined,
    videos: data.videos?.length ? bindingVideosMapper(data.videos) : undefined,
    places: data.places?.length ? baseBindingMapper(data.places) : undefined,
    services: data.services?.length ? baseBindingMapper(data.services) : undefined,
    recommendedEvents: data.recommendedEvents?.length ? baseBindingMapper(data.recommendedEvents) : undefined,
  };
};

export const mapAuthorForBackend = (formFields: FrontendAuthorItem) => {
  const { name, announcement, description, isTopAuthor, showInTagCloud, showInRecommendation, noShowInMain, priority } =
    formFields;
  return {
    name,
    announcement,
    description,
    priority,
    roles: formFields.roles?.map((role) => {
      return role.key;
    }),
    events: formFields.events?.map((event) => {
      return +event.value;
    }),
    services: formFields.services?.map((service) => {
      return +service.value;
    }),
    news: formFields.news?.map((_new) => {
      return +_new.value;
    }),
    places: formFields.places?.map((place) => {
      return +place.value;
    }),
    isTopAuthor,
    recommendedEvents: formFields.recommendedEvents?.map((recEvent) => {
      return +recEvent.value;
    }),
    noShowInMain,
    showInRecommendation,
    showInTagCloud,
    videos: formFields.videos?.map((video) => {
      return video.value;
    }),
  };
};

export const baseBindingMapper = <T extends { title?: string; id: number }>(
  data?: Array<T>,
  selectedIds?: number[],
) => {
  const filteredData =
    selectedIds && selectedIds?.length > 0 ? data?.filter((item) => !selectedIds.includes(item.id)) : data;
  return filteredData?.map((item) => {
    return {
      label: item.title && item.title.length > 50 ? `${item.title.slice(0, 50)}...` : item.title || '',
      value: item.id,
    };
  });
};

export const bindingVideosMapper = (data?: Array<AuthorVideoResponse>, selectedIds?: number[]) => {
  const filteredData =
    selectedIds && selectedIds?.length > 0 ? data?.filter((item) => !selectedIds.includes(item.id)) : data;
  return filteredData?.map((item) => {
    return {
      label: item.title.length > 50 ? `${item.title.slice(0, 50)}...` : item.title,
      value: item.url,
      key: item.id,
    };
  });
};

export const baseAuthorBindingMapper = (data?: AdminAuthorRolesListResponseDto[], selectedIds?: number[]) => {
  const filteredData =
    selectedIds && selectedIds?.length > 0 ? data?.filter((item) => !selectedIds.includes(item.id)) : data;
  return filteredData?.map((item) => {
    return {
      label: item.name.length > 50 ? `${item.name.slice(0, 50)}...` : item.name,
      value: item.id,
    };
  });
};

export const rolesMapper = (data?: RolesListResponse['data'] | ClientRole[] | Role[]) => {
  return data?.map((item) => {
    return {
      label: item.name,
      key: item.id,
      value: item.name,
    };
  });
};

export const mapCities = (data?: AdminCitiesAreasResponseDto[]) => {
  return data?.map(({ name, id }) => {
    return { label: name, key: id, value: name };
  });
};
